import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { gettoken } from "../../Localstorage/Store";

export const cartApi = createApi({
  reducerPath: 'cartApi',
  baseQuery: fetchBaseQuery({ baseUrl: `${process.env.REACT_APP_API_URL}`, prepareHeaders: (headers, { getState }) => {
    const tokenvalue = gettoken();
    if (tokenvalue) {
      headers.set('Authorization',`Bearer ${tokenvalue.access}`);
    }
    return headers;
  },}),
  endpoints: (builder) => ({
    getCartProduct: builder.query({
      query: () => ({
        url: `homepageapi/BasketView`,
        method:'GET'
      })
    }),
    getRemoveCredit: builder.query({
      query: () => ({
        url: `credit/credit-remove-cart/`,
        method:'GET'
      })
    }),
    deleteCart: builder.mutation({
        query: ({id,cartid}) => ({
          url: `homepageapi/DeleteCartProduct/${id}/${cartid}`,
          method:'DELETE'
        })
      }),
    deleteCredit: builder.mutation({
        query: ({id,cartid}) => ({
          url: `credit/credit-remove-cart/`,
          method:'POST'
        })
      }),
  }),
})

export const { useGetCartProductQuery,useGetRemoveCreditQuery,useDeleteCartMutation,useDeleteCreditMutation } = cartApi