import { Field,Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import {  useNavigate } from "react-router-dom";
import { checkoutvalidation } from "../Validation/Checkout";
import { gethelp } from "../Validation/gethelp";
import axios from "axios";
import { gettoken } from "../Localstorage/Store";

const Landigpage = () => {
  const [selectoption,setselectoption] = useState('')
  const [addressstatus,setaddressstatus] = useState('')
  const [success,setsuccess] = useState('')
  const closeaddressbtn = useRef(null)
  const nvg = useNavigate()
  const tokenvalue = gettoken()

  const handleEdit3 = () => {
    const buttonNode = closeaddressbtn.current;
    if (buttonNode) {
      buttonNode.click();
    }
  };
  const createaddress  = async (values,action,setFieldError) =>{
      try {
          // const data = {
          //     first_name: values.first_name,
          //     last_name: values.last_name,
          //     phone_number: values.mobile,
          //     email: values.email,
          //     message: values.msg
          // }
          const formdata = new FormData()
        // formdata.append('bank_name',values.bankname)
        // formdata.append('bank_acc_no',values.bankaccountnumber)
        // formdata.append('bank_address',values.bankaddress)
        formdata.append('first_name',values.first_name)
        formdata.append('last_name',values.last_name)
        formdata.append('phone_number',values.mobile)
        formdata.append('email',values.email)
        formdata.append('message',values.msg)
        formdata.append('image',values.image)
        
          let url = `${process.env.REACT_APP_API_URL}useraccount/get-help/`;
          const response = await axios.post(
            url,
            formdata
          );
          if(response.data.status == "success"){
            // setsuccess("Message Send Successfully")
            setaddressstatus(true)
            action();
          }
          console.log("sssssssssssssssssssss",response)

        //  setloading(false)
         setTimeout(() => {
          setaddressstatus(false)
          handleEdit3()
         }, 3000);
        } catch (error) {}
  
  }
  console.log("this is testing",selectoption)

  const checkuser = async () =>{
    const config = {
      headers: {
        Authorization: `Bearer ${tokenvalue.access}`,
        "Content-Type": "application/json",
      },
    };
    let url = `${process.env.REACT_APP_API_URL}useraccount/clientinfo/`;
    try {
      const response = await axios.get(url, config);
      console.log("this is ere",response.data)
        nvg('/home')
    } catch (error) {
    console.log("qwertyui")
    }
  }

  useEffect(()=>{
    if(tokenvalue){
        // nvg('/home')
        checkuser()
    }
  },[])
  return (
    <div className="container">
      <div className="row justify-content-center">   
      <div className="col-12">
        <div className="row d-flex justify-content-center">
          <div className="col-5 py-5 d-flex justify-content-center">
          <img
            src={`./images/logo.png`}
            // src={`${process.env.REACT_APP_API_PUBLIC_URL}/images/logo.png`}
            className="img-fluid resizewidth2"
            // style={{ width:'80%' }}
            style={{ maxWidth:'400px' }}
            alt="logo"
          />
          </div>
        </div>
      </div>
      <div className="col-12 mb-4">
        <h2 className="text-center" style={{fontSize:"32px",fontWeight:'700'}}>Welcome!</h2>
      </div>
      <div className="col-lg-4 col-sm-12" onClick={()=>{setselectoption('customer')}} style={{marginTop:'30px'}}>
        <div className={selectoption == "customer" ? "designbox catboxforshadow" : "designbox"} style={{position:'relative',gap:'11px',padding: '32px',border: '1px solid #cacaca',borderRadius: '8px',backgroundColor: '#fff',cursor: 'pointer',display:'flex'}}>
          <div className="firstline d-flex justify-content-center align-items-center" style={{position:'relative'}}>
            {/* <div>{selectoption == "customer" ? <input type="radio" name="" value={selectoption} checked onClick={()=>{setselectoption('customer')}} id="" style={{height:'21px',width:'19px',position: 'absolute',top: '-15px',right: '-7px'}} /> : <input type="radio" name="" value={selectoption}  onClick={()=>{setselectoption('customer')}} id="" style={{height:'21px',width:'19px',position: 'absolute',top: '-15px',right: '-7px'}}  />}</div> */}
            <div className="d-flex justify-content-center align-items-center" style={{height:'100px',width: '100px',backgroundColor: '#ececec',borderRadius: '50%'}}><img
            src={`${process.env.REACT_APP_API_PUBLIC_URL}/images/customer.png`}
            // src={`http://localhost:3000/images/customer.png`}
            className="img-fluid"
            // style={{ width:'80%' }}
            alt="logo"
          /></div>
          </div>

          <div className="secondline py-2" style={{fontSize: '28px',lineHeight: '32px',fontWeight: '600',fontStyle: 'normal',color: 'rgb(42, 42, 42)',textAlign:'start',display:'flex',justifyContent:'center',flexDirection:'column'}}> <span>Voucher </span><span style={{fontSize:'20px',position:'relative',left:'2px'}}>Login</span></div>
          <div>{selectoption == "customer" ? <div onClick={()=>{setselectoption('customer')}} id="" style={{border:'1px solid #6a6868',background:'#230bb3',borderRadius:'50%',height:'21px',width:'21px',position: 'absolute',top: '13px',right: '13px'}}></div> : <div onClick={()=>{setselectoption('customer')}} id="" style={{border:'1px solid #6a6868',borderRadius:'50%',height:'21px',width:'21px',position: 'absolute',top: '13px',right: '13px'}}></div>}</div>
        </div>
        <div>
          <p style={{fontSize:'14px',textAlign:'center',fontWeight:'600',color:'#000',padding:'5px',marginTop:'13px',cursor:'pointer',border:'1px solid rgb(202, 202, 202)',borderRadius:'5px'}} onClick={()=>{nvg('/track-order')}} >  <img
            src={`${process.env.REACT_APP_API_PUBLIC_URL}/images/track.png`}
            // src={`http://localhost:3000/images/track.png`}
            className="img-fluid"
            // style={{ width:'80%' }}
            alt="logo"
          /> Track Your Order</p>
        </div>
      </div>
      <div className="col-lg-4 col-sm-12" onClick={()=>{setselectoption('member')}} style={{marginTop:'30px'}}>
        <div className={selectoption == "member" ? "designbox catboxforshadow" : "designbox"} style={{position:'relative',gap:'11px',padding: '32px',border: '1px solid #cacaca',borderRadius: '8px',backgroundColor: '#fff',cursor: 'pointer',display:'flex'}}>
          <div className="firstline d-flex justify-content-center align-items-center" style={{position:'relative'}}>
            <div className="d-flex justify-content-center align-items-center" style={{height:'100px',width: '100px',backgroundColor: '#ececec',borderRadius: '50%'}}><img
            src={`${process.env.REACT_APP_API_PUBLIC_URL}/images/setting.png`}
            // src={`http://localhost:3000/images/setting.png`}
            className="img-fluid"
            // style={{ width:'24px' }}
            alt="logo"
          /></div>
            {/* <div><input type="radio" name="" id="" style={{height:'21px',width:'17px'}} /></div> */}
          </div>
          <div className="secondline py-2" style={{fontSize: '28px',lineHeight: '32px',fontWeight: '600',fontStyle: 'normal',color: 'rgb(42, 42, 42)',textAlign:'start',display:'flex',justifyContent:'center',flexDirection:'column'}}> <span>Business </span><span style={{fontSize:'20px',position:'relative',left:'2px'}}>Login</span></div>
          <div>{selectoption == "member" ? <div onClick={()=>{setselectoption('member')}} id="" style={{border:'1px solid #6a6868',background:'#230bb3',borderRadius:'50%',height:'21px',width:'21px',position: 'absolute',top: '13px',right: '13px'}}></div> : <div onClick={()=>{setselectoption('member')}} id="" style={{border:'1px solid #6a6868',borderRadius:'50%',height:'21px',width:'21px',position: 'absolute',top: '13px',right: '13px'}}></div>}</div>
          {/* <div className="secondline py-2" style={{fontSize: '25px',lineHeight: '30px',fontWeight: '600',fontStyle: 'normal',color: 'rgb(42, 42, 42)',textAlign:'center'}}>Menber Login</div> */}
          {/* <div className="thirdline" style={{fontSize: '16px',lineHeight: '24px',fontStyle: 'normal',color: 'rgb(106, 106, 106)'}}>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptatum eum quos odit earum quasi libero. Qui inventore neque similique sed?</div> */}
        </div>
        <div>
          <p style={{fontSize:'14px',textAlign:'center',fontWeight:'600',color:'#000',marginTop:'13px',cursor:'pointer',border:'1px solid rgb(202, 202, 202)',padding:'5px',borderRadius:'5px'}} onClick={()=>{nvg('/registerbusiness')}}> <img
            src={`${process.env.REACT_APP_API_PUBLIC_URL}/images/register.png`}
            // src={`http://localhost:3000/images/register.png`}
            className="img-fluid"
            // style={{ width:'24px' }}
            alt="logo"
          /> Register Your Business</p>
        </div>
      </div>

      <div className="col-12 d-flex justify-content-center py-4" style={{marginTop:'10px'}}>
        <button className="iDrQLK" onClick={()=>{selectoption == "" ? <></> : selectoption == "customer" ? nvg('/voucherlogin',{ state: { id: 1} }) : nvg("/clientlogin")}} style={{background:selectoption == '' ? '' : '#230bb3'}} >
           <span style={{color:'white'}}> Continue </span><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.16663 10.0001H15.8333M15.8333 10.0001L9.99995 4.16675M15.8333 10.0001L9.99995 15.8334" stroke="#FFFFFF" stroke-width="1.66666" stroke-linecap="round" stroke-linejoin="round"></path></svg></button>
      </div>
      <div className="col-12 d-flex justify-content-center py-3" style={{minHeight:'130px'}}>
<p className="d-flex">
Having trouble with Login?  <span className="d-flex" style={{color:'rgb(29, 97, 246)',cursor:'pointer'}}   data-bs-toggle="modal"
                          data-bs-target="#exampleModaladdressone2"> Get Help  <svg width="12" height="12" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.5 7.50001L17.5 2.50001M17.5 2.50001H12.5M17.5 2.50001L10 10M8.33333 2.5H6.5C5.09987 2.5 4.3998 2.5 3.86502 2.77248C3.39462 3.01217 3.01217 3.39462 2.77248 3.86502C2.5 4.3998 2.5 5.09987 2.5 6.5V13.5C2.5 14.9001 2.5 15.6002 2.77248 16.135C3.01217 16.6054 3.39462 16.9878 3.86502 17.2275C4.3998 17.5 5.09987 17.5 6.5 17.5H13.5C14.9001 17.5 15.6002 17.5 16.135 17.2275C16.6054 16.9878 16.9878 16.6054 17.2275 16.135C17.5 15.6002 17.5 14.9001 17.5 13.5V11.6667" stroke="#1D61F6" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"></path></svg>
</span>
</p>

      </div>
      </div>


      <div
            class="modal fade"
            id="exampleModaladdressone2"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"  
          >
            <div class="modal-dialog" style={{ maxWidth: "770px" }}>
              <div class="modal-content">
                <div class="modal-header">
                  <h1 class="modal-title fs-5" style={{position:'relative',left:'42%'}} id="exampleModalLabel">
                   Get Help
                  </h1>
                  <button
                    type="button"
                    ref={closeaddressbtn}
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body">
                  <Formik
                    initialValues={{
                      first_name: "",
                      last_name: "",
                      email: "",
                      mobile: "",
                      msg: "",
                      image: '',
                    }}
                    validationSchema={gethelp}
                    onSubmit={(values, { setFieldError,resetForm }) => {

                      console.log("this is values of data",values)
                      createaddress(values, resetForm);
                    }}
                  >
                    {({
                      values,
                      errors,
                      handleSubmit,
                      touched,
                      handleChange,
                      setFieldValue,
                      setFieldError,
                    }) => (
                      <Form autoComplete="off" onSubmit={handleSubmit}>
                        <div className="row check-out ">
                         
                          <div className="form-group col-md-6 col-sm-6 col-xs-12">
                            <label className="acounttitle m-0">
                              First Name{" "}
                              <span style={{ color: "red", fontSize: "13px" }}>
                                {" "}
                                *
                              </span>
                            </label>
                            {/* <input type="text" name="field-name" placeholder='First Name' /> */}
{console.log("this is all values and errors",values,"and errors",errors)}
                            <Field
                              type="text"
                              name="first_name"
                              className="form-control"
                              style={{ fontSize: "12px" }}
                              placeholder="Enter Your First Name"
                              value={values?.first_name}
                            />
                            <div className="error">
                              {errors.first_name && touched.first_name ? (
                                <p style={{ color: "red" }}>
                                  {errors.first_name}
                                </p>
                              ) : null}
                            </div>
                          </div>
                          <div className="form-group col-md-6 col-sm-6 col-xs-12">
                            <label className="acounttitle m-0">
                              Last Name
                              <span style={{ color: "red", fontSize: "13px" }}>
                                {" "}
                                *
                              </span>
                            </label>
                            {/* <input type="text" name="field-name" placeholder='Last Name' /> */}
                            <Field
                              name="last_name"
                              type="text"
                              className="form-control"
                              style={{ fontSize: "12px" }}
                              placeholder="Enter Your Last Name"
                              value={values?.last_name}
                            />
                            <div className="error">
                              {errors.last_name && touched.last_name ? (
                                <p style={{ color: "red" }}>
                                  {errors.last_name}
                                </p>
                              ) : null}
                            </div>
                          </div>
                          <div className="form-group col-md-6 col-sm-6 col-xs-12">
                            <label className="acounttitle field-label m-0">
                              Phone
                              <span style={{ color: "red", fontSize: "13px" }}>
                                {" "}
                                *
                              </span>
                            </label>
                            {/* <input type="text" name="field-name" placeholder='Phone' /> */}
                            <Field
                              name="mobile"
                              type="tel"
                              style={{ fontSize: "12px" }}
                              className="form-control"
                              placeholder="Enter Your Mobile. No"
                              value={values?.mobile}
                              onChange={(e) => {
                                setFieldValue(
                                  "mobile",
                                  e.target.value.replace(/\D/g, "")
                                );
                              }}
                            />
                            <div className="error">
                              {errors.mobile && touched.mobile ? (
                                <p style={{ color: "red" }}>{errors.mobile}</p>
                              ) : null}
                            </div>
                          </div>
                          <div className="form-group col-md-6 col-sm-6 col-xs-12">
                            <label className="acounttitle field-label m-0">
                              Email Address
                              <span style={{ color: "red", fontSize: "13px" }}>
                                {" "}
                                *
                              </span>
                            </label>
                            {/* <input type="text" name="field-name" placeholder='Email'/> */}
                            <Field
                              name="email"
                              type="email"
                              className="form-control"
                              style={{ fontSize: "12px" }}
                              placeholder="Enter Your Email Address"
                              value={values?.email}
                            />
                            <div className="error">
                              {errors.email && touched.email ? (
                                <p style={{ color: "red" }}>{errors.email}</p>
                              ) : null}
                            </div>
                          </div>



                          <div className="form-group col-md-12 col-sm-12 col-xs-12">
                            <label className="acounttitle field-label m-0">
                              Message
                              <span style={{ color: "red", fontSize: "13px" }}>
                                {" "}
                                *
                              </span>
                            </label>
                            <textarea className="form-control" style={{fontSize:'12px'}} name="msg"  value={values.msg}
                                            onChange={handleChange} placeholder="Write Your Message" rows={2} defaultValue={""} />

                            <div className="error">
                              {errors.msg && touched.msg ? (
                                <p style={{ color: "red" }}>
                                  {errors.msg}
                                </p>
                              ) : null}
                            </div>
                            {/* <input type="text" name="field-name" placeholder="House number and street name" /> */}

                            {/* <input type="text" name="field-name" className="mt-2" placeholder="Apartment, suite, unit etc. (optional)" /> */}
                          </div>


 <div className="form-group col-md-12 col-sm-12 col-xs-12">
                            <label className="acounttitle field-label m-0">
                              Image
                              {/* <span style={{ color: "red", fontSize: "13px" }}>
                                {" "}
                                *
                              </span> */}
                            </label>
                            {/* <Field
                              name="image"
                              type="file"
                              style={{ fontSize: "12px" }}
                              className="form-control mt-2"
                              // placeholder="Apartment, suite, unit etc."
                              value={values.image}
                            /> */}
                            <input
                  type="file"
                  name="image"
                  className="form-control"
                  style={{fontSize:'12px'}}
                  // style={{ borderBottom: errors.authorizationletter && touched.authorizationletter ? "1px solid red" : "" }}
                  onChange={(event) => {
                    setFieldValue("image", event.currentTarget.files[0]);
                  }}
                />
                            {/* <div className="error">
                              {errors.image && touched.image ? (
                                <p style={{ color: "red" }}>
                                  {errors.image}
                                </p>
                              ) : null}
                            </div> */}
                            {/* <input type="text" name="field-name" placeholder="House number and street name" /> */}

                            {/* <input type="text" name="field-name" className="mt-2" placeholder="Apartment, suite, unit etc. (optional)" /> */}
                          </div>

                         
                         

                          <div className="form-group col-md-12 col-sm-12 col-xs-12 cht">
                            <div className="payment-box">
                              <div
                                className="text-left"
                                style={{
                                  display: "flex",
                                  justifyContent: "end",
                                  gap: "4px",
                                }}
                              >
                                <button
                                  type="button"
                                  style={{
                                    fontSize: "12px",
                                    background: "#bdbfc0",
                                    color: "#000",
                                  }}
                                  className="btn-normal btn"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                  // ref={buttonRef}
                                >
                                  Cancel
                                </button>
                                <button
                                  type="submit"
                                  style={{ fontSize: "12px" }}
                                  className="btn-normal btn"
                                >
                                  Send
                                </button>
                              </div>
                            </div>
                          </div>

                          <div className="form-group col-md-12 col-sm-12 col-xs-12 cht">
                            <div
                              class="alert alert-success"
                              role="alert"
                              style={{
                                display:
                                  addressstatus == true ? "block" : "none",
                              }}
                            >
                             Message Send Successfully
                            </div>
                          </div>
                        </div>
                      </Form>
                    
                    )}
                  </Formik>
                </div>
                {/* <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div> */}
              </div>
            </div>
          </div>
    </div>
  );
};

export default Landigpage;
