import React, { useEffect, useRef, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { gettoken, removeToken } from "../Localstorage/Store";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useGetBannerQuery,
  useGetBestSellerQuery,
  useGetBrandItemQuery,
  useGetCategoryItemQuery,
  useGetFeatureProductQuery,
  useGetNewArrivalQuery,
} from "../store/api/bannerapi";
import axios from "axios";

const Home = () => {
  const tokenvalue = gettoken();
  const nvg = useNavigate();
  const openwarning = useRef(null);
  const [totalbrand, settotalbrand] = useState(0);
  const history = useLocation();
  console.log("ddkdkd the location fo", history);
  // useEffect(()=>{
  // if(history?.state?.id){
  //   console.log("ddkdkdk ckeck 1   1")
  //   if(openwarning.current){
  //     console.log("ddkdkdk ckeck 2   2222")
  //     openwarning.current.click()
  //   }
  // }
  // },[])
  // console.log("brand data",brands)
  const options = {
    items: 1,
    loop: true,
    autoplay: true,
    nav: false,
    responsiveClass: true,
    dots: false,
    responsive: {
      1200: {
        items: 1,
      },
      920: {
        items: 1,
      },
      700: {
        items: 1,
      },
      600: {
        items: 1,
      },
      504: {
        items: 1,
      },
      300: {
        items: 1,
      },
      310: {
        items: 1,
      },
    },
  };
  const options2 = {
    items: 5,
    loop: true,
    autoplay: true,
    nav: true,
    responsiveClass: true,
    dots: false,
    responsive: {
      1200: {
        items: 5,
        // stagePadding: 50,
      },
      920: {
        items: 4,
      },
      700: {
        items: 3,
      },
      600: {
        items: 3,
      },
      504: {
        items: 2,
      },
      300: {
        items: 2,
      },
      310: {
        items: 1,
      },
    },
  };
  const optionsforbrand = {
    items: 6,
    loop: totalbrand > 6 ? true : false,
    autoplay: true,
    nav: true,
    responsiveClass: true,
    dots: false,
    responsive: {
      1200: {
        items: 6,
        // stagePadding: 50,
      },
      920: {
        items: 5,
      },
      700: {
        items: 4,
      },
      600: {
        items: 4,
      },
      504: {
        items: 3,
      },
      300: {
        items: 2,
      },
      310: {
        items: 1,
      },
    },
  };
  const options6 = {
    items: 2,
    loop: true,
    autoplay: false,
    nav: false,
    responsiveClass: true,
    dots: false,
    responsive: {
      1200: {
        items: 2,
        // stagePadding: 50,
      },
      920: {
        items: 2,
      },
      700: {
        items: 2,
      },
      600: {
        items: 2,
      },
      504: {
        items: 2,
      },
      300: {
        items: 2,
      },
      310: {
        items: 1,
      },
    },
  };
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const transfer = (value, title) => {
    // nvg("/productdetails", { state: { id: value, pagename: title } });
    console.log("check values",value,"and tilte",title)
    // window.location.reload();
    nvg("/productdetails", {
      state: {
        id: value,
        // categoryid: id,
        // pagename: name,
        pname: title,
        // categoryid: location.state?.id,
        // pagename: location.state?.pagename,
      },
    });
    window.location.reload();
  };

  const transferdetails = (productid, categories) => {
    // console.log(categories, "test came categ test");
    nvg("/productdetails", {
      state: {
        id: productid,
        // categoryid: categories,
        pagename: categories,
      },
    });
    window.location.reload();
  };

  const [owl, setowl] = useState("tab-1");

  const { data: Banner, isLoading: Bannerloader } = useGetBannerQuery();
  const { data: brands, isLoading: Brandloader } = useGetBrandItemQuery();
  // console.log("sddddddvvbhhyfddddd", brands);
  const { data, isLoading: Bestsellerloader } = useGetBestSellerQuery();
  const { data: newarrivals, isLoading: NewArrivalloader } =
    useGetNewArrivalQuery();
  const { data: categories, isLoading: categoryloader } =
    useGetCategoryItemQuery();
  const { data: featureproduct, isLoading: featureloader } =
    useGetFeatureProductQuery();
  // console.log("feature item", featureproduct);
  const [warninginfo, setwarninginfo] = useState({});

  const getLowestOrderImage = (images) => {
    if (!images || images.length === 0) return null;
    return images.reduce((lowest, image) => {
      return lowest === null || image.display_order < lowest.display_order
        ? image
        : lowest;
    }, null);
  };

  useEffect(() => {
    setIsMobile(window.innerWidth <= 768);
    if (Brandloader == false) {
      settotalbrand(brands.brands.length);
    }
    // if (history.state) {
    //   if (history.state.id) {
    //     console.log("qqqqddkdkdk ckeck sss",history.state.id);
    //     if (history.state.id == 2) {
    //       setwarninginfo({
    //         numlogin: history.state.id,
    //         msg: <><span>1 Out of 3 Login</span> <br /> <br /> <span>{history.state.message}</span></>,
    //       });
    //     } else {
    //       setwarninginfo({
    //         numlogin: history.state.id,
    //         msg: "You have Only 1 Login Attempt Left",
    //       });
    //     }
    //   }
    // }

    async function Cartvalueget() {
      const config = {
        headers: {
          Authorization: `Bearer ${tokenvalue.access}`,
          "Content-Type": "application/json",
        },
      };
      let url = `${process.env.REACT_APP_API_URL}homepageapi/CartCountOfUser`;
      try {
        const response = await axios.get(url, config);
        // setCartnumber(response.data.cart_count)
      } catch (error) {
        // console.log("error unsr", error.response.status);
        if (error.response.status == 401) {
          removeToken();
          // window.location.reload();
          window.location.href = "/";
        }
      }
    }
    Cartvalueget();
  }, [brands, Brandloader, openwarning]);

  return Bannerloader == true ||
    Brandloader == true ||
    Bestsellerloader == true ||
    NewArrivalloader == true ||
    categoryloader == true ? (
    ""
  ) : (
    <div className="bg-light">
      <Header />
      {Bannerloader == true ? (
        ""
      ) : (
        <section className="sale-banenr banner-style2 design2 marginfromtop">
          <OwlCarousel
            className="owl-theme"
            style={{ width: "100%", height: "100%" }}
            {...options}
          >
            {Banner.map((item, index) => (
              <div key={index} style={{ height: isMobile ? "300px" : "400px" }}>
                <img
                  onClick={() => {
                    window.location.href = item.link;
                  }}
                  src={`${process.env.REACT_APP_API_URL2}${item.image}`}
                  alt={`banner-${index}`}
                  className="img-fluid mainbanner bg-img"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "100% 100%",
                  }}
                />
                {/* <div className="custom-container design3 newdesgin">
          <div className="row">
            <div className="col-12 position-relative">
  
              <img
                src={`https://apanel.oneupbrands.com${item.logo}`}

                alt="logo"
                className="logo-image"
                style={{
                  position: 'absolute',
                  right: isMobile ? 'calc(100% - 1px)' : '10px',  

                  top: '10px', 
                  width: isMobile ? '100px' : '200px',
                  height: '50px', 
                  objectFit: 'scale-down',
                  zIndex: 2,  
                }}
              />

              <div className="sale-banenr-contain text-center p-right">
                <div style={{ height: isMobile ? 'auto' : '450px', display: 'flex', flexDirection: 'column', justifyContent: isMobile ? 'center' : 'flex-start', alignItems: isMobile ? 'center' : 'flex-start' }}>
                  <h2 style={{ fontSize: '1.5em', fontWeight: 'bold', color: 'white' }}>
                    {item.title}
                  </h2>


                  <button
                type='button'
                onClick={() => {
                  if (item.breadcrumbs) {
                    transfer(item.category_id, item.breadcrumbs);
                  } else {
                    transfer(item.category_id, item.link);
                  }
                }}
                className="btn btn-rounded"
              >
                Shop Now
              </button>
                </div>
              </div>
            </div>
          </div>
        </div> */}
              </div>
            ))}
          </OwlCarousel>
        </section>
      )}

      <section className="megastore-slide collection-banner section-py-space b-g-white">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-12 col-lg-12">
              {/* <div className="row">
                {categories.map((category) => (
                  <div
                    className="col-md-4"
                    style={{ paddingBottom: 12 }}
                    key={category.id}
                  >
                    <div className="collection-banner-main banner-18 banner-style7 collection-color13 p-left text-center">
                      <div className="collection-img">
                        <img
                          onClick={() => {
                            window.location.href = category.link;
                          }}
                          src={
                            category.image
                              ? `https://apanel.oneupbrands.com${category.image}`
                              : "images/mega-store/slider/banner/placeholder.jpg"
                          }
                          className="img-fluid bg-img"
                          alt={category.category_name}
                        />
                      </div>
                      <div className="collection-banner-contain">
                        <div>
                          <h3>{category.category_name}</h3>
                          <h3>{category.title}</h3>

                          <div
                            dangerouslySetInnerHTML={{
                              __html: category.description,
                            }}
                          />
                          <button
                            type="button"
                            onClick={() => {
                              window.location.href = category.Link;
                            }}
                            className="btn btn-rounded"
                            style={{ padding: "12px 24px" }}
                          >
                            Shop Now
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div> */}



<div className="row">
                {categories.map((categorySet) => (
                  <div key={categorySet.name}>
                    <div className="title8">
                      <h4 style={{ fontSize: '16px' }}> {categorySet.name}</h4>
                    </div>
                    {/* <h4 style={{ fontSize: '16px' }}>{categorySet.name}</h4> */}
                    <div className="row">
                      {categorySet.promotions.map((promotion) => (
                        <div className="col-md-4" style={{ paddingBottom: 12 }} key={promotion.id}>
                          <div className="collection-banner-main banner-18 banner-style7 collection-color13 p-left text-center">
                            <div className="collection-img">
                              <img
                                onClick={() => { window.location.href = promotion.Link }}
                                src={`https://apanel.oneupbrands.com${promotion.image}`}
                                className="img-fluid bg-img"
                                alt={promotion.category_name}
                              />
                            </div>
                            <div className="collection-banner-contain">
                              <div>
                                <h3>{promotion.category_name}</h3>
                                <h3>{promotion.title}</h3>
                                <div dangerouslySetInnerHTML={{ __html: promotion.description }} />
                                <button
                                  type='button'
                                  onClick={() => { window.location.href = promotion.Link }}
                                  className="btn btn-rounded"
                                  style={{ padding: '12px 24px' }}>
                                  Shop Now
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>


                  </div>
                ))}
              </div>








              
            </div>
          </div>
        </div>
      </section>
      {/*home mine banner end*/}

      {/*title start*/}
      {/* <div className="title8 section-big-pt-space"> */}
      <div className="title8">
        <h4 style={{ fontSize: "16px" }}> new arrivals</h4>
      </div>
      {/*title end*/}
      {/* new arrivals product tab start */}
      <section className="section-big-mb-space ratio_square product">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 pr-0">
              <div className="product-slide-5 product-m no-arrow">
                <OwlCarousel
                  className="owl-theme"
                  style={{ width: "100%", height: "100%" }}
                  {...options2}
                >
                  {newarrivals.map((item) => {

var lowestOrderImage = getLowestOrderImage(item.images);
                    return(
                    <div
                      className="catbox"
                      style={{ marginRight: "12px", marginBottom: "3px" }}
                    >
                      <div
                        className="product-box product-box2"
                        style={{ paddingRight: "0px"}}
                        onClick={() => {
                          // nvg("/productdetails", {
                          //   state: {
                          //     id: item.id,
                          //     pname: item.title,
                          //     categoryid: item.id,
                          //     pagename: item.title,
                          //   },
                          // });
                          console.log("abckddkdkd",item.id,item.title)
                        }}
                      >
                        
                       
                        <div className="product-imgbox2new" 
                        // <div className="product-imgbox" 
                        style={{position: "relative", overflow: "hidden"}}
                        >
                        {item.stockrecords[0]?.num_in_stock <= 0 && (<div className="overlay" onClick={() => {
                                              transfer(item.id, item.title);
                                            }}></div>)}
                                        {item.stockrecords[0]?.num_in_stock <= 0 && (
                                          <div className="out-of-stock">Out of Stock</div>
                                        )}
                          <div className="product-front">
                            <a href="javascript:void(0)">
                              <img src={lowestOrderImage?.original} alt="" />
                              {/* <img src={item.images?.[0]?.original} alt="" /> */}
                            </a>
                          </div>

                          {/* <div className="on-sale1">on sale</div> */}
                        </div>
                        <div className="product-detail product-detail2">
                          <a href="javascript:void(0)">
                            <h6
                              className="price-title catbox2"
                              style={{
                                fontSize: "14px",
                                fontWeight: "600",
                                color: "#230bb3",
                                height: "20px",
                                display: "-webkit-box",
                                WebkitLineClamp: "1",
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                              }}
                            >
                              {item?.attributes?.brand}
                            </h6>
                            {/* <h3 style={{fontWeight: 600,fontSize:'14px'}}>{item.title}</h3> */}
                            <h6
                              className="price-title catbox2"
                              style={{
                                fontSize: "14px",
                                fontWeight: "600",
                                display: "-webkit-box",
                                WebkitLineClamp: "1",
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                              }}
                            >
                              {/* {item.title.length > 10 ? item.title.substring(0,18) + '...' : item.title} */}
                              {item?.title}
                            </h6>{" "}
                          </a>
                          <h5
                            style={{
                              fontSize: "14px",
                              fontWeight: "500",
                              color: "black",
                            }}
                          >
                            ₹{parseInt(item.stockrecords[0]?.price)}
                            {item.stockrecords[0]?.discount == 0 ? (
                              ""
                            ) : (
                              <span
                                style={{
                                  fontSize: "13px",
                                  color: "#c1c1c1",
                                  lineHeight: "20px",
                                  textDecoration: "line-through",
                                  paddingLeft: "3px",
                                  fontWeight: "400",
                                }}
                              >
                                ₹{parseInt(item.stockrecords[0]?.mrp)}
                              </span>
                            )}
                            {item.stockrecords[0]?.discount == 0 ? (
                              ""
                            ) : (
                              <span
                                style={{
                                  fontSize: "12px",
                                  textDecoration: "none",
                                  color: "#388e3c",
                                  lineHeight: "20px",
                                  paddingLeft: "3px",
                                  fontWeight: "400",
                                }}
                              >
                                {item.stockrecords[0]?.discount_type == "amount"
                                  ? `(₹${item.stockrecords[0]?.discount} off)`
                                  : `(${item.stockrecords[0]?.discount} %off)`}
                              </span>
                            )}
                          </h5>
                        </div>
                      </div>
                    </div>
                  )})}
                </OwlCarousel>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="title8" style={{ display: data?.[0] ? "block" : "none" }}>
        <h4 style={{ fontSize: "16px" }}>Best Sellers</h4>
      </div>
      {/*title end*/}
      {/* best seller start  */}
      <section className="section-big-mb-space ratio_square product">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 pr-0">
              <div className="product-slide-5 product-m no-arrow">
                <OwlCarousel
                  className="owl-theme"
                  style={{ width: "100%", height: "100%" }}
                  {...options2}
                >
                  {data.map((item) => {
                     const lowestOrderImage = getLowestOrderImage(item.images);
                    return (
                    <div
                      className="catbox"
                      style={{ marginRight: "12px", marginBottom: "3px" }}
                    >
                      <div
                        className="product-box product-box2"
                        style={{ paddingRight: "0px" }}
                        onClick={() => {
                          nvg("/productdetails", {
                            state: {
                              id: item.id,
                              pname: item.title,
                            },
                          });
                        }}
                      >
                         
                        <div className="product-imgbox2new" 
                        style={{ paddingRight: "0px", position: "relative", overflow: "hidden" }}
                        >
                        {item.stockrecords[0].num_in_stock <= 0 && (<div className="overlay" onClick={() => {
                                              transfer(item.id, item.title);
                                            }}></div>)}
                                        {item.stockrecords[0].num_in_stock <= 0 && (
                                          <div className="out-of-stock">Out of Stock</div>
                                        )}
                          <div className="product-front">
                            <a href="javascript:void(0)">
                              <img src={lowestOrderImage?.original} alt="" />
                              {/* <img src={item.images[0]?.original} alt="" /> */}
                              {/* ${process.env.REACT_APP_API_URL}media/${item?.image[item.image.length - 1]?.original} */}
                            </a>
                          </div>
                          {/* <div className="product-back">
                    <a href="/home">
                      <img src="images/layout-2/product/a1.jpg" className="img-fluid" alt="product" />
                    </a>
                  </div> */}

                          {/* <div className="on-sale1">on sale</div> */}
                        </div>
                        <div className="product-detail product-detail2">
                          <a href="javascript:void(0)">
                            <h6
                              className="price-title catbox2"
                              style={{
                                fontSize: "14px",
                                fontWeight: "600",
                                color: "#230bb3",
                                height: "20px",
                                display: "-webkit-box",
                                WebkitLineClamp: "1",
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                              }}
                            >
                              {/* {item.title.length > 10 ? item.title.substring(0,18) + '...' : item.title} */}
                              {item?.attributes?.brand}
                            </h6>{" "}
                            <h6
                              style={{
                                fontSize: "14px",
                                fontWeight: "600",
                                display: "-webkit-box",
                                WebkitLineClamp: "1",
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                              }}
                            >
                              {item.title}
                            </h6>
                          </a>
                          <h5
                            style={{
                              fontSize: "14px",
                              fontWeight: "500",
                              color: "black",
                            }}
                          >
                            ₹{parseInt(item.stockrecords[0]?.price)}
                            {item.stockrecords[0]?.discount == 0 ? (
                              ""
                            ) : (
                              <span
                                style={{
                                  fontSize: "13px",
                                  color: "#c1c1c1",
                                  lineHeight: "20px",
                                  textDecoration: "line-through",
                                  paddingLeft: "3px",
                                  fontWeight: "400",
                                }}
                              >
                                ₹{parseInt(item.stockrecords[0]?.mrp)}
                              </span>
                            )}
                            {item.stockrecords[0]?.discount == 0 ? (
                              ""
                            ) : (
                              <span
                                style={{
                                  fontSize: "12px",
                                  textDecoration: "none",
                                  color: "#388e3c",
                                  lineHeight: "20px",
                                  paddingLeft: "3px",
                                  fontWeight: "400",
                                }}
                              >
                                {item.stockrecords[0]?.discount_type == "amount"
                                  ? `(₹${item.stockrecords[0]?.discount} off)`
                                  : `(${item.stockrecords[0]?.discount} %off)`}
                              </span>
                            )}
                          </h5>
                        </div>
                      </div>
                    </div>
                  )})}
                </OwlCarousel>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*title-start*/}
      {featureproduct?.results?.[0] ? (
        <div className="title8 section-mb-space">
          <h4 style={{ fontSize: "16px" }}>Featured Products</h4>
        </div>
      ) : (
        ""
      )}

      {/*title-end*/}
      {/*tab product*/}
      <section
        className="tab-product-main tab-second"
        style={{ display: featureproduct?.results ? featureproduct?.results?.[0] ? "block" : "none" : "none" }}
      >
        <div className="tab-prodcut-contain">
          <ul
            className="tabs tab-title"
            style={{ display: featureproduct?.results ? "block" : "none" }}
          >
            {featureproduct?.results ? featureproduct.results.map((item, index) => (
              <li className={owl == `tab-${index + 1}` ? "current" : ""}>
                <a
                  href="javascript:void(0)"
                  onClick={() => setowl(`tab-${index + 1}`)}
                  style={{ fontSize: "14px" }}
                >
                  {item?.category}
                </a>
              </li>
            )) : ''}
            {/* <li className={owl == 'tab-2' ? 'current' : ''} ><a href='javascript:void(0)' onClick={()=>setowl('tab-2')} style={{fontSize:'14px'}}>furniture</a></li>
        <li className={owl == 'tab-3' ? 'current' : ''} ><a href='javascript:void(0)' onClick={()=>setowl('tab-3')} style={{fontSize:'14px'}}>electronic</a></li>
        <li className={owl == 'tab-4' ? 'current' : ''} ><a href='javascript:void(0)' onClick={()=>setowl('tab-4')} style={{fontSize:'14px'}}>sports</a></li>
        <li className={owl == 'tab-5' ? 'current' : ''} ><a href='javascript:void(0)' onClick={()=>setowl('tab-5')} style={{fontSize:'14px'}}>medicine</a></li> */}
          </ul>
        </div>
      </section>
      {/*tab product*/}
      {/* product start */}
      <section className="section-big-py-space" style={{display:featureproduct?.results?.[0] ? "block" : "none"}}>
        <div className="custom-container">
          <div className="row">
            <div className="col-12 p-0">
              <div className="theme-tab product">
                <div className="tab-content-cls">
                  {featureproduct?.results ? featureproduct?.results.map((item, index) => (
                    <div
                      id={`tab-${index + 1}`}
                      style={{
                        display: owl == `tab-${index + 1}` ? "block" : "none",
                      }}
                      className={
                        owl == `tab-${index + 1}`
                          ? "tab-content active default product-block3"
                          : "tab-content product-block3"
                      }
                    >
                      <div className="col-12">
                        <div className="product-slide-3 featuredisply no-arrow">
                          {/* <OwlCarousel className="owl-theme" style={{width:'100%',height:'100%'}} {...options6}> */}

                          <div className="feature-width">
                            <div
                              className="product-box3 catbox"
                              onClick={() => {
                                nvg("/productdetails", {
                                  state: {
                                    id: item.products?.[0]?.id,
                                    pname: item.products?.[0]?.title,
                                  },
                                });
                              }}
                            >
                              <div className="media">
                                <div
                                  className="img-wrapper"
                                  style={{ width: "280px", height: "259px" }}
                                >
                                  {/* <a href="/home" style={{width:'280px'}}> */}
                                  <img
                                    className="img-fluid"
                                    style={{ width: "100%", height: "100%" }}
                                    onClick={() => {
                                      nvg("/productdetails", {
                                        state: {
                                          id: item.products?.[0]?.id,
                                          pname: item.products?.[0]?.title,
                                        },
                                      });
                                    }}
                                    src={`${process.env.REACT_APP_API_URL}media/${item.products?.[0]?.image[0]}`}
                                    alt="product"
                                  />
                                  {/* </a>  */}
                                </div>
                                <div
                                  className="media-body"
                                  onClick={() => {
                                    nvg("/productdetails", {
                                      state: {
                                        id: item.products?.[0]?.id,
                                        pname: item.products?.[0]?.title,
                                      },
                                    });
                                  }}
                                >
                                  <div className="product-detail">
                                    {/* <ul className="rating">
                                <li className='px-1'>
                                  <a href="https://www.facebook.com/oneuptrade/"><i className="fa-brands fa-facebook-f" /></a>
                                </li>
                                <li className='px-1'>
                                  <a href="https://www.linkedin.com/company/oneuptrade/"><i className="fa-brands fa-linkedin" /></a>
                                </li>
                                <li className='px-1'>
                                  <a href="javascript:void(0)"><i className="fa-brands fa-youtube" /></a>
                                </li>
                                <li>
                                  <a href="https://www.instagram.com/oneuptrade/"><i className="fa-brands fa-instagram" /></a>
                                </li>
                                
                              </ul> */}
                                    {/* <a href="/home"> */}
                                    <h3
                                      style={{
                                        fontSize: "13px",
                                        color: "#230bb3",
                                        marginBottom: "5px",
                                      }}
                                    >
                                      {item.products?.[0]?.att_val
                                        .filter(
                                          (item) =>
                                            item.attribute_name.toLowerCase() ==
                                            "brand"
                                        )
                                        .map((item, index) => item.value)}
                                    </h3>
                                    <h3 style={{ fontSize: "13px" }}>
                                      {item.products?.[0]?.title}
                                    </h3>
                                    {/* </a> */}
                                    {/* <h4 style={{fontSize:'12px'}}>₹70<span>₹90</span></h4> */}
                                    <h4
                                      onClick={() => {
                                        nvg("/productdetails", {
                                          state: {
                                            id: item.products?.[0]?.id,
                                            pname: item.products?.[0]?.title,
                                          },
                                        });
                                      }}
                                    >
                                      ₹{item.products?.[0]?.stock_record?.price}{" "}
                                      {item.products?.[0]?.stock_record
                                        ?.discount == 0 ? (
                                        ""
                                      ) : (
                                        <span
                                          style={{
                                            fontSize: "13px",
                                            color: "#c1c1c1",
                                            lineHeight: "20px",
                                            textDecoration: "line-through",
                                            paddingLeft: "3px",
                                            fontWeight: "400",
                                          }}
                                        >
                                          ₹
                                          {
                                            item.products?.[0]?.stock_record
                                              ?.mrp
                                          }
                                        </span>
                                      )}
                                      {item.products?.[0]?.stock_record
                                        ?.discount == 0 ? (
                                        ""
                                      ) : (
                                        <span
                                          style={{
                                            fontSize: "12px",
                                            color: "#388e3c",
                                            lineHeight: "20px",
                                            paddingLeft: "3px",
                                            fontWeight: "400",
                                          }}
                                        >
                                          {item.products?.[0]?.stock_record
                                            ?.discount_type == "amount"
                                            ? `(₹${item.products?.[0]?.stock_record?.discount} off)`
                                            : `(${item.products?.[0]?.stock_record?.discount} %off)`}
                                        </span>
                                      )}
                                    </h4>
                                    <button
                                      className="btn btn-rounded btn-sm"
                                      style={{
                                        fontSize: "12px",
                                        backgroundColor: "#230bb3",
                                      }}
                                      href="#"
                                      onClick={() => {
                                        nvg("/productdetails", {
                                          state: {
                                            id: item.products?.[0]?.id,
                                            pname: item.products?.[0]?.title,
                                          },
                                        });
                                      }}
                                    >
                                      shop now
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="feature-width"
                            style={{
                              display: item.products?.[1]?.title
                                ? "block"
                                : "none",
                            }}
                          >
                            <div
                              className="product-box3 catbox"
                              onClick={() => {
                                nvg("/productdetails", {
                                  state: {
                                    id: item.products?.[0]?.id,
                                    pname: item.products?.[0]?.title,
                                  },
                                });
                              }}
                            >
                              <div className="media">
                                <div
                                  className="img-wrapper"
                                  style={{ width: "280px", height: "259px" }}
                                >
                                  {/* <a href="/home" style={{width:'280px'}}> */}
                                  <img
                                    className="img-fluid"
                                    style={{ width: "100%", height: "100%" }}
                                    src={`${process.env.REACT_APP_API_URL}media/${item.products?.[1]?.image[0]}`}
                                    alt="product"
                                  />
                                  {/* </a>  */}
                                </div>
                                <div className="media-body">
                                  <div className="product-detail">
                                    {/* <ul className="rating">
                                <li className='px-1'>
                                  <a href="https://www.facebook.com/oneuptrade/"><i className="fa-brands fa-facebook-f" /></a>
                                </li>
                                <li className='px-1'>
                                  <a href="https://www.linkedin.com/company/oneuptrade/"><i className="fa-brands fa-linkedin" /></a>
                                </li>
                                <li className='px-1'>
                                  <a href="javascript:void(0)"><i className="fa-brands fa-youtube" /></a>
                                </li>
                                <li>
                                  <a href="https://www.instagram.com/oneuptrade/"><i className="fa-brands fa-instagram" /></a>
                                </li>
                                
                              </ul> */}
                                    {/* <a href="/home"> */}
                                    <h3
                                      style={{
                                        fontSize: "13px",
                                        color: "#230bb3",
                                        marginBottom: "5px",
                                      }}
                                    >
                                      {item.products?.[1]?.att_val
                                        .filter(
                                          (item) =>
                                            item.attribute_name.toLowerCase() ==
                                            "brand"
                                        )
                                        .map((item, index) => item.value)}
                                    </h3>
                                    <h3 style={{ fontSize: "13px" }}>
                                      {item.products?.[1]?.title}
                                    </h3>
                                    {/* </a> */}
                                    {/* <h4 style={{fontSize:'12px'}}>₹70<span>₹90</span></h4> */}
                                    <h4>
                                      ₹{item.products?.[1]?.stock_record?.price}{" "}
                                      {item.products?.[1]?.stock_record
                                        ?.discount == 0 ? (
                                        ""
                                      ) : (
                                        <span
                                          style={{
                                            fontSize: "13px",
                                            color: "#c1c1c1",
                                            lineHeight: "20px",
                                            textDecoration: "line-through",
                                            paddingLeft: "3px",
                                            fontWeight: "400",
                                          }}
                                        >
                                          ₹
                                          {
                                            item.products?.[1]?.stock_record
                                              ?.mrp
                                          }
                                        </span>
                                      )}
                                      {item.products?.[1]?.stock_record
                                        ?.discount == 0 ? (
                                        ""
                                      ) : (
                                        <span
                                          style={{
                                            fontSize: "12px",
                                            color: "#388e3c",
                                            lineHeight: "20px",
                                            paddingLeft: "3px",
                                            fontWeight: "400",
                                          }}
                                        >
                                          {item.products?.[1]?.stock_record
                                            ?.discount_type == "amount"
                                            ? `(₹${item.products?.[1]?.stock_record?.discount} off)`
                                            : `(${item.products?.[1]?.stock_record?.discount} %off)`}
                                        </span>
                                      )}
                                    </h4>
                                    <button
                                      className="btn btn-rounded btn-sm"
                                      style={{
                                        fontSize: "12px",
                                        backgroundColor: "#230bb3",
                                      }}
                                      onClick={() => {
                                        nvg("/productdetails", {
                                          state: {
                                            id: item.products?.[1]?.id,
                                            pname: item.products?.[1]?.title,
                                          },
                                        });
                                      }}
                                    >
                                      shop now
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="product-slide-3 featuredisply no-arrow">
                          {/* <OwlCarousel className="owl-theme" style={{width:'100%',height:'100%'}} {...options6}> */}

                          <div
                            className="feature-width"
                            style={{
                              display: item.products?.[2]?.title
                                ? "block"
                                : "none",
                            }}
                          >
                            <div
                              className="product-box3 catbox"
                              onClick={() => {
                                nvg("/productdetails", {
                                  state: {
                                    id: item.products?.[0]?.id,
                                    pname: item.products?.[0]?.title,
                                  },
                                });
                              }}
                            >
                              <div className="media">
                                <div
                                  className="img-wrapper"
                                  style={{ width: "280px", height: "259px" }}
                                >
                                  {/* <a href="/home" style={{width:'280px'}}> */}
                                  <img
                                    className="img-fluid"
                                    style={{ width: "100%", height: "100%" }}
                                    src={`${process.env.REACT_APP_API_URL}media/${item.products?.[2]?.image[0]}`}
                                    alt="product"
                                  />
                                  {/* </a>  */}
                                </div>
                                <div className="media-body">
                                  <div className="product-detail">
                                    {/* <ul className="rating">
                                <li className='px-1'>
                                  <a href="https://www.facebook.com/oneuptrade/"><i className="fa-brands fa-facebook-f" /></a>
                                </li>
                                <li className='px-1'>
                                  <a href="https://www.linkedin.com/company/oneuptrade/"><i className="fa-brands fa-linkedin" /></a>
                                </li>
                                <li className='px-1'>
                                  <a href="javascript:void(0)"><i className="fa-brands fa-youtube" /></a>
                                </li>
                                <li>
                                  <a href="https://www.instagram.com/oneuptrade/"><i className="fa-brands fa-instagram" /></a>
                                </li>
                                
                              </ul> */}
                                    {/* <a href="/home"> */}
                                    <h3
                                      style={{
                                        fontSize: "13px",
                                        color: "#230bb3",
                                        marginBottom: "5px",
                                      }}
                                    >
                                      {item.products?.[2]?.att_val
                                        .filter(
                                          (item) =>
                                            item.attribute_name.toLowerCase() ==
                                            "brand"
                                        )
                                        .map((item, index) => item.value)}
                                    </h3>
                                    <h3 style={{ fontSize: "13px" }}>
                                      {item.products?.[2]?.title}
                                    </h3>
                                    {/* </a> */}
                                    {/* <h4 style={{fontSize:'12px'}}>₹70<span>₹90</span></h4> */}
                                    <h4>
                                      ₹{item.products?.[2]?.stock_record?.price}{" "}
                                      {item.products?.[2]?.stock_record
                                        ?.discount == 0 ? (
                                        ""
                                      ) : (
                                        <span
                                          style={{
                                            fontSize: "13px",
                                            color: "#c1c1c1",
                                            lineHeight: "20px",
                                            textDecoration: "line-through",
                                            paddingLeft: "3px",
                                            fontWeight: "400",
                                          }}
                                        >
                                          ₹
                                          {
                                            item.products?.[2]?.stock_record
                                              ?.mrp
                                          }
                                        </span>
                                      )}
                                      {item.products?.[2]?.stock_record
                                        ?.discount == 0 ? (
                                        ""
                                      ) : (
                                        <span
                                          style={{
                                            fontSize: "12px",
                                            color: "#388e3c",
                                            lineHeight: "20px",
                                            paddingLeft: "3px",
                                            fontWeight: "400",
                                          }}
                                        >
                                          {item.products?.[2]?.stock_record
                                            ?.discount_type == "amount"
                                            ? `(₹${item.products?.[2]?.stock_record?.discount} off)`
                                            : `(${item.products?.[2]?.stock_record?.discount} %off)`}
                                        </span>
                                      )}
                                    </h4>
                                    <button
                                      className="btn btn-rounded btn-sm"
                                      style={{
                                        fontSize: "12px",
                                        backgroundColor: "#230bb3",
                                      }}
                                      onClick={() => {
                                        nvg("/productdetails", {
                                          state: {
                                            id: item.products?.[2]?.id,
                                            pname: item.products?.[2]?.title,
                                          },
                                        });
                                      }}
                                    >
                                      shop now
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="feature-width"
                            style={{
                              display: item.products?.[3]?.title
                                ? "block"
                                : "none",
                            }}
                          >
                            <div
                              className="product-box3 catbox"
                              onClick={() => {
                                nvg("/productdetails", {
                                  state: {
                                    id: item.products?.[0]?.id,
                                    pname: item.products?.[0]?.title,
                                  },
                                });
                              }}
                            >
                              <div className="media">
                                <div
                                  className="img-wrapper"
                                  style={{ width: "280px", height: "259px" }}
                                >
                                  {/* <a href="/home" style={{width:'280px'}}> */}
                                  <img
                                    className="img-fluid"
                                    style={{ width: "100%", height: "100%" }}
                                    src={`${process.env.REACT_APP_API_URL}media/${item.products?.[3]?.image[0]}`}
                                    alt="product"
                                  />
                                  {/* </a>  */}
                                </div>
                                <div className="media-body">
                                  <div className="product-detail">
                                    {/* <ul className="rating">
                                <li className='px-1'>
                                  <a href="https://www.facebook.com/oneuptrade/"><i className="fa-brands fa-facebook-f" /></a>
                                </li>
                                <li className='px-1'>
                                  <a href="https://www.linkedin.com/company/oneuptrade/"><i className="fa-brands fa-linkedin" /></a>
                                </li>
                                <li className='px-1'>
                                  <a href="javascript:void(0)"><i className="fa-brands fa-youtube" /></a>
                                </li>
                                <li>
                                  <a href="https://www.instagram.com/oneuptrade/"><i className="fa-brands fa-instagram" /></a>
                                </li>
                                
                              </ul> */}
                                    {/* <a href="/home"> */}
                                    <h3
                                      style={{
                                        fontSize: "13px",
                                        color: "#230bb3",
                                        marginBottom: "5px",
                                      }}
                                    >
                                      {item.products?.[3]?.att_val
                                        .filter(
                                          (item) =>
                                            item.attribute_name.toLowerCase() ==
                                            "brand"
                                        )
                                        .map((item, index) => item.value)}
                                    </h3>
                                    <h3 style={{ fontSize: "13px" }}>
                                      {item.products?.[3]?.title}
                                    </h3>
                                    {/* </a> */}
                                    {/* <h4 style={{fontSize:'12px'}}>₹70<span>₹90</span></h4> */}
                                    <h4>
                                      ₹{item.products?.[3]?.stock_record?.price}{" "}
                                      {item.products?.[3]?.stock_record
                                        ?.discount == 0 ? (
                                        ""
                                      ) : (
                                        <span
                                          style={{
                                            fontSize: "13px",
                                            color: "#c1c1c1",
                                            lineHeight: "20px",
                                            textDecoration: "line-through",
                                            paddingLeft: "3px",
                                            fontWeight: "400",
                                          }}
                                        >
                                          ₹
                                          {
                                            item.products?.[3]?.stock_record
                                              ?.mrp
                                          }
                                        </span>
                                      )}
                                      {item.products?.[3]?.stock_record
                                        ?.discount == 0 ? (
                                        ""
                                      ) : (
                                        <span
                                          style={{
                                            fontSize: "12px",
                                            color: "#388e3c",
                                            lineHeight: "20px",
                                            paddingLeft: "3px",
                                            fontWeight: "400",
                                          }}
                                        >
                                          {item.products?.[3]?.stock_record
                                            ?.discount_type == "amount"
                                            ? `(₹${item.products?.[3]?.stock_record?.discount} off)`
                                            : `(${item.products?.[3]?.stock_record?.discount} %off)`}
                                        </span>
                                      )}
                                    </h4>
                                    <button
                                      className="btn btn-rounded btn-sm"
                                      style={{
                                        fontSize: "12px",
                                        backgroundColor: "#230bb3",
                                      }}
                                      href="#"
                                      onClick={() => {
                                        nvg("/productdetails", {
                                          state: {
                                            id: item.products?.[3]?.id,
                                            pname: item.products?.[3]?.title,
                                          },
                                        });
                                      }}
                                    >
                                      shop now
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )) : ''}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* product end */}

      {/*title-start*/}
      <div
        className="title8 section-mb-space"
        style={{ display: brands.brands[0] ? "block" : "none" }}
      >
        <h4 style={{ fontSize: "16px" }}>Shop By Brands</h4>
      </div>
      {/*title-end*/}
      {/* brand start */}
      <section className="brand-second section-big-mb-space">
        <div className="container-fluid">
          <div className="row brand-block">
            <div className="col-12">
              <div className="brand-slide12 no-arrow mb--5">
                <OwlCarousel
                  className="owl-theme"
                  style={{ width: "100%", height: "100%" }}
                  {...optionsforbrand}
                >
                  {brands.brands.map((item, index) => (
                    <div className="catbox mb-1">
                      <div
                        className="brand-box "
                        onClick={() => {
                          nvg(`/categoryforbrand/${item.name}`);
                        }}
                      >
                        <img
                          src={`${process.env.REACT_APP_API_URL}media/${item.logo}`}
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  ))}
                </OwlCarousel>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* brand start */}
      {/* footer start */}
      <Footer />
      {/* footer end */}
      {/* Quick-view modal popup start*/}
      <div
        className="modal fade bd-example-modal-lg theme-modal"
        id="quick-view"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-lg modal-dialog-centered"
          role="document"
        >
          <div className="modal-content quick-view-modal">
            <div className="modal-body">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
              <div className="row">
                <div className="col-lg-6 col-xs-12">
                  <div className="quick-view-img">
                    <img
                      src="images/mega-store/product/8.jpg"
                      alt
                      className="img-fluid bg-img"
                    />
                  </div>
                </div>
                <div className="col-lg-6 rtl-text">
                  <div className="product-right">
                    <div className="pro-group" style={{ padding: 0 }}>
                      <h2>wireless headphones</h2>
                      <ul className="pro-price">
                        <li>₹70</li>
                        <li>
                          <span>mrp ₹140</span>
                        </li>
                        <li>50% off</li>
                      </ul>
                      <div className="revieu-box">
                        <ul>
                          <li>
                            <i className="fa fa-star" />
                          </li>
                          <li>
                            <i className="fa fa-star" />
                          </li>
                          <li>
                            <i className="fa fa-star" />
                          </li>
                          <li>
                            <i className="fa fa-star" />
                          </li>
                          <li>
                            <i className="fa fa-star-o" />
                          </li>
                        </ul>
                        {/* <a href="review.html"><span>(6 reviews)</span></a> */}
                      </div>
                    </div>
                    <div className="pro-group">
                      <h6 className="product-title">product infomation</h6>
                      <p>
                        Sed ut perspiciatis, unde omnis iste natus error sit
                        voluptatem accusantium doloremque laudantium
                      </p>
                    </div>
                    <div className="pro-group pb-0">
                      <h6 className="product-title size-text">
                        select size
                        <span>
                          <a
                            href
                            data-bs-toggle="modal"
                            data-bs-target="#sizemodal"
                          >
                            size chart
                          </a>
                        </span>
                      </h6>
                      <div
                        className="modal fade"
                        id="sizemodal"
                        tabIndex={-1}
                        role="dialog"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <div
                          className="modal-dialog modal-dialog-centered"
                          role="document"
                        >
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5
                                className="modal-title"
                                id="exampleModalLabel"
                              >
                                Sheer Straight Kurta
                              </h5>
                              <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              />
                            </div>
                            <div className="modal-body">
                              <img
                                src="images/size-chart.jpg"
                                alt
                                className="img-fluid"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="size-box">
                        <ul>
                          <li>
                            <a href="javascript:void(0)">s</a>
                          </li>
                          <li>
                            <a href="javascript:void(0)">m</a>
                          </li>
                          <li>
                            <a href="javascript:void(0)">l</a>
                          </li>
                          <li>
                            <a href="javascript:void(0)">xl</a>
                          </li>
                          <li>
                            <a href="javascript:void(0)">2xl</a>
                          </li>
                        </ul>
                      </div>
                      <h6 className="product-title">color</h6>
                      <div className="color-selector inline">
                        <ul>
                          <li>
                            <div className="color-1 active" />
                          </li>
                          <li>
                            <div className="color-2" />
                          </li>
                          <li>
                            <div className="color-3" />
                          </li>
                          <li>
                            <div className="color-4" />
                          </li>
                          <li>
                            <div className="color-5" />
                          </li>
                          <li>
                            <div className="color-6" />
                          </li>
                          <li>
                            <div className="color-7" />
                          </li>
                        </ul>
                      </div>
                      <h6 className="product-title">quantity</h6>
                      <div className="qty-box">
                        <div className="input-group">
                          <button className="qty-minus" />
                          <input
                            className="qty-adj form-control"
                            type="number"
                            defaultValue={1}
                          />
                          <button className="qty-plus" />
                        </div>
                      </div>
                      <div className="product-buttons">
                        <a
                          href="javascript:void(0)"
                          onclick="openCart()"
                          className="btn cart-btn btn-normal tooltip-top"
                          data-tippy-content="Add to cart"
                        >
                          <i className="fa fa-shopping-cart" />
                          add to cart
                        </a>
                        <a
                          href="/home"
                          className="btn btn-normal tooltip-top"
                          data-tippy-content="view detail"
                        >
                          view detail
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Quick-view modal popup end*/}
      {/* edit product modal start*/}
      <div
        className="modal fade bd-example-modal-lg theme-modal pro-edit-modal"
        id="edit-product"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-lg modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-body">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
              <div className="pro-group">
                <div className="product-img">
                  <div className="media">
                    <div className="img-wraper">
                      <a href="/home">
                        <img
                          src="images/mega-store/product/9.jpg"
                          alt
                          className="img-fluid"
                        />
                      </a>
                    </div>
                    <div className="media-body">
                      <a href="/home">
                        <h3>redmi not 3</h3>
                      </a>
                      <h6>
                        ₹80<span>₹120</span>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pro-group">
                <h6 className="product-title">Select Size</h6>
                <div className="size-box">
                  <ul>
                    <li>
                      <a href="javascript:void(0)">s</a>
                    </li>
                    <li>
                      <a href="javascript:void(0)">m</a>
                    </li>
                    <li>
                      <a href="javascript:void(0)">l</a>
                    </li>
                    <li>
                      <a href="javascript:void(0)">xl</a>
                    </li>
                    <li>
                      <a href="javascript:void(0)">2xl</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="pro-group">
                <h6 className="product-title">Select color</h6>
                <div className="color-selector inline">
                  <ul>
                    <li>
                      <div className="color-1 active" />
                    </li>
                    <li>
                      <div className="color-2" />
                    </li>
                    <li>
                      <div className="color-3" />
                    </li>
                    <li>
                      <div className="color-4" />
                    </li>
                    <li>
                      <div className="color-5" />
                    </li>
                    <li>
                      <div className="color-6" />
                    </li>
                    <li>
                      <div className="color-7" />
                    </li>
                  </ul>
                </div>
              </div>
              <div className="pro-group">
                <h6 className="product-title">Quantity</h6>
                <div className="qty-box">
                  <div className="input-group">
                    <button className="qty-minus" />
                    <input
                      className="qty-adj form-control"
                      type="number"
                      defaultValue={1}
                    />
                    <button className="qty-plus" />
                  </div>
                </div>
              </div>
              <div className="pro-group mb-0">
                <div className="modal-btn">
                  <a href="cart.html" className="btn btn-solid btn-sm">
                    add to cart
                  </a>
                  <a href="/home" className="btn btn-solid btn-sm">
                    more detail
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* edit product modal end*/}

      <div
        className={warninginfo.numlogin ? "modal fade show" : "modal fade"}
        id="exampleModal1400000"
        tabindex="-1"
        style={{ display: warninginfo.numlogin ? "block" : "none" }}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered ">
          <div className="modal-content">
            {/* <span className="question-logo" style={{display:"flex",justifyContent:"center",alignItems:'center'}} ></span> */}
            {/* <i class="fa fa-exclamation" aria-hidden="true"></i> */}
            <div className="modal-header mod-line d-none"></div>
            <div className="modal-body">
              <div className="row gy-3 mt-2">
                {/* <div className="d-flex justify-content-center">
          <BsQuestionLg className='question-logo' />
            </div> */}
                <h4
                  style={{
                    color: "rgb(35, 11, 179)",
                    cursor: "pointer",
                    textAlign: "center",
                    fontSize: "21px",
                    fontWeight: "800",
                    margin: "0px",
                  }}
                >
                  Voucher/Coupon Info
                </h4>
                <p
                  className="ccedit-p "
                  style={{ textAlign: "center", fontSize: "12px" }}
                >
                  {warninginfo.msg}
                </p>
              </div>
            </div>
            <div
              className="modal-footer mod-line m-auto"
              style={{ border: "none" }}
            >
              {/* <button type="button" className="btn closecancel" data-bs-dismiss="modal"
                aria-label="Close">Cancel</button> */}
              {/* <button
                  type="button"
                  className="btn closebtn "
                  style={{
                    paddingRight: "20px",
                    fontSize: "12px",
                    fontWeight: "600",
                    color: "#230BB3",
                  }}
                  data-bs-dismiss="modal"
                  // aria-label="Close"
                >
                  Cancel
                </button> */}
              <button
                type="button"
                className="btn closebtn profile-edit-btn justhoverwh"
                data-bs-dismiss="modal"
                // aria-label="Close"
                // onClick={() => {
                //   delrecord();
                // }}
                onClick={() => {
                  nvg("/home", {});
                  window.location.reload();
                }}
                style={{
                  borderRadius: "0px",
                  fontSize: "13px",
                  fontWeight: "600",
                  padding: "4px 8px",
                }}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
