import React, { useEffect, useState } from 'react'
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Header from '../components/Header';
import Footer from '../components/Footer';
import { useNavigate } from 'react-router-dom';
import { AiOutlinePlus, AiOutlineCheck } from "react-icons/ai";
import axios from 'axios';
import { gettoken } from '../Localstorage/Store';
import { IoIosArrowDown } from "react-icons/io";
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export const Mycredit = () => {



  const nvg = useNavigate()
  const [data, setData] = useState([]);
  const [datatwo, setDatatwo] = useState([]);
  const [error, seterror] = useState("No Records found !");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  console.log("this is value",startDate,endDate)


  const [datastatus, setDatastatus] = useState([]);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  // console.log("selectedStatus", selectedStatuses)
  const [selectedDuration, setSelectedDuration] = useState(null);
  // console.log(selectedDuration, "selectedDuration")



  const [userloader, setuserloader] = useState(false);
  const [creditamount, setcreditamount] = useState(0);

  const tokenvalue = gettoken();
  const [loading, setloading] = useState(false)
  const currentwdith = window.innerWidth;





console.log("ggggggggggggg",datatwo)

  const clearFilter = () => {
    setSelectedStatuses([]); // Clear selectedStatuses array
    setSelectedDuration(null);
    fetchcategory() // Reset selectedDuration to null (or initial value)
  };

  useEffect(() => {
    async function fetchstatus() {
      const config = {
        headers: {
          Authorization: `Bearer ${tokenvalue.access}`,
        },
      };
      let url = `${process.env.REACT_APP_API_URL}credit/credit-history/`;

      const response = await axios.get(url, config);
      setDatastatus(response.data);
    }



    async function creditAmountCheck() {
      // console.log(tokenvalue.access);
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${tokenvalue.access}`,
          },
        };
        setuserloader(true);
        let url = `${process.env.REACT_APP_API_URL}credit/total_amount/`;
        const response = await axios.get(url, config);
        // console.log("this is wallet amount", response);
        // console.log(response.status);
        if ((response.status = "200")) {
          setcreditamount(response.data.amount);
          // setcreditminusamount(response.data.minus_amount)
          // setcreditshippingstatus(response.data.shipping_value)
        // settotalcredit(response.data.amount);
          // setuserloader(false);
          // console.log(response.amount);
          // return response.data.amount;
        }

        //  setloading(false)
      } catch (error) {}
    }

    creditAmountCheck()
    fetchstatus()

  }, [])
  const [searchTerm, setSearchTerm] = useState('');
  const handleSearch = async () => {
    if (searchTerm == '') {
      fetchcategory()
    } else {
      const searchTermLower = searchTerm.trim().toLowerCase();

      const filteredOrders = data.filter(order =>
        order.number.toLowerCase().includes(searchTermLower)
      );
      setData(filteredOrders)

      // console.log('Filtered orders:', filteredOrders);
      if (data.length > 0 && selectedDuration) {
        if (selectedDuration == '360') {
          const errorMessage = "No orders found last one year "
          seterror(errorMessage);

        } if (selectedDuration == '180') {
          const errorMessage = "No orders found last six Month "
          seterror(errorMessage);

        }
        if (selectedDuration == '30') {
          const errorMessage = "No orders found last 30 Days "
          seterror(errorMessage);

        }
        if (selectedDuration == '15') {
          const errorMessage = "No orders found last 15 Days "
          seterror(errorMessage);

        }



      }
    }

  };



  async function fetchcategory() {
    setloading(true)
    let url = `${process.env.REACT_APP_API_URL}credit/credit-history/`;
    const config = {
      headers: {
        Authorization: `Bearer ${tokenvalue.access}`,
      },
    };
    // let url = ${process.env.REACT_APP_API_URL}api/orders/;
    const response = await axios.get(url,config);
    // console.log("order history response", response)
    // console.log("order history response data", response.data)

    setData(response.data.history);
    setloading(false)
  }
  useEffect(() => {
    // statement()
    fetchcategory()

  }, [])





const [statementerror,setstatementerror] = useState('')

  const statement = async () => {
    // console.log("values of fiedl",values);

    setloading(true)
        try {

          let data = {
              user: tokenvalue.id,
            };

            const config = {
              headers: {
                Authorization: `Bearer ${tokenvalue.access}`,
              },
            };
         
          let url = `${process.env.REACT_APP_API_URL}credit/credit-history-csv/?start_date=${startDate}&end_date=${endDate}`;
          const response = await axios.get(url, config);
          console.log("this ier eror msfvvvvvvvvvv")
    
      console.log("this",response)
     // Parse the CSV data
     const lines = response.data.trim().split('\n');

     // Extract the data rows (ignoring the header row)
     const parsedData = lines.slice(1).map((line) => {
       const [creditId, creditUsedAmount, creditUsedTime, orderId, requestId] = line.split(',');

       return {
        //  'Credit ID': creditId,
         'Credit Used Amount': creditUsedAmount,
         'Credit Used Time': new Date(creditUsedTime).toLocaleString(), // Format date
         'Order ID': orderId || '',
         'Request ID': requestId || '',
       };
     });

     setDatatwo(parsedData);
              setstatementerror('')
   
     handleDownloadPDF(parsedData)
         
        } catch (error) {
          setloading(false)
          console.log("this ier eror msfvvvvvvvvvvrrrr")
          if(error.response){

            if(error.response.data.error == 'Credit history not found'){
              setstatementerror("Credit history not found");
              setTimeout(() => {
                setstatementerror('')
              }, 4000);
            }
          }

        }
      };





      
      const handleDownloadPDF = (value4) => {
        if (!Array.isArray(value4) || value4.length === 0) {
          console.error('Data is not an array or is empty');
          return;
        }
      
        // Create a new jsPDF instance
        const doc = new jsPDF();
      
        // Add a title to the PDF
        doc.setFontSize(16);
        doc.text('User Statement', 10, 10);
      
        // Add table with data, excluding Credit ID
        doc.autoTable({
          startY: 20,
          head: [['S.No', 'Amount Used', 'Used Time', 'Transaction ID']],
          body: value4.map((item, index) => [
            index + 1, // S.No
            item['Credit Used Amount'],
            item['Credit Used Time'],
            item['Order ID'] ? `OR${item['Order ID']}` : `CR${item['Request ID']}`
          ]),
          theme: 'grid',
          styles: { fontSize: 10, rowHeight: 10, cellPadding: 5,overflow: 'linebreak', },
          headStyles: { fillColor: [35, 11, 179], textColor: 255 }, // White text color for header
          footStyles: { fillColor: [35, 11, 179] },
          didParseCell: (data) => {
            // Apply color only to data rows (skip header)
            if (data.section === 'body' && data.column.index === 1) { 
              const cellValue = parseFloat(data.cell.text[0]);
              if (!isNaN(cellValue) && cellValue < 0) {
                data.cell.styles.textColor = [255, 0, 0];
              } else {
                data.cell.styles.textColor = [0, 128, 0]; 
              }
            }
          },
          didDrawPage: (data) => {
            // Add page number
            const pageCount = doc.internal.getNumberOfPages();
            doc.setFontSize(10);
            doc.text(`Page ${doc.internal.getCurrentPageInfo().pageNumber} of ${pageCount}`, data.settings.margin.left, doc.internal.pageSize.height - 10);
          },
        });
      
        // Save the PDF
        doc.save('UserStatement.pdf');
      };
        
      
    



  return (
    <div>
      <Header />

      <div>
        {/* breadcrumb start */}
        <div className="breadcrumb-main marginfromtop" style={{ background: "#fff" }}>
          <div className="container m-0">
            <div className="row">
              <div className="col">
                <div className="breadcrumb-contain">
                  <div>
                    <ul>
                      <li><a href="/home">home</a></li>
                      <li onClick={()=>{statement()}}>{`>`}</li>
                      <li><a href="javascript:void(0)">My Credits </a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* breadcrumb End */}
        <div>
          <section className="section-big-pt-space py-1 " style={{ background: "#f9f9f9" }}>
            <div className="col-lg-12 col-sm-12 col-xs-12 mt-lg-4 mt-xs-4 mb-5">

              <div className="container-fuild emp-profile">
                <div className="row pro justify-content-center">


                  {/* <div id="tab-2" style={{ display: owl == 'tab-2' ? 'block' : 'none' }} className={owl == 'tab-2' ? "tab-content active default product-block3" : "tab-content product-block3"}> */}
                  <div className="row py-xl-4 py-3">

 <div className={"col-xl-10 col-md-12 offset-xl-1 offset-md-0 Ohistory"} style={{ padding: "0px 4px" }} >
                      <h3 className='pb-xl-0 pb-md-0 pb-2' style={{color:'#230bb3'}}> My Credits </h3>
                      </div>


                    <div className={"col-xl-10 col-md-12 offset-xl-1 offset-md-0 Ohistory"} style={{ padding: "0px 4px" }} >
                      <div className="input-group mb-3" style={{ gap: '10px', justifyContent: 'space-between',alignItems:'end' }}>
                        <span>
                          <span style={{display:'block'}}>Company Name : <span style={{textTransform:'uppercase'}}> {tokenvalue.company_name}</span></span>
                          <span style={{display:'block'}}>Account ID : {tokenvalue.client_id}</span>
                          <span>
                        Credits Available ₹ {creditamount}
                          </span>
                        </span>
                        <span style={{fontSize:'19px',fontWeight:'500'}}>
                        Transactions
                        </span>
                      <div style={{display:'flex',flexDirection:'column',gap:'3px'}}>
                        <button
                          className='Sbtn btn btn-outline-secondary'
                          style={{ background: '#43A047', color: 'white',padding:'5px 6px', borderRadius: '0px', fontSize: "13px" }}
                          type="button"
                          id="button-addon2"
                          onClick={() => { nvg('/creditrequest') }}
                        >
                          {/* <i className="fa fa-search"></i> */}
                          <span style={{ fontSize: '18px' }}>+</span>&nbsp; Request Credits
                        </button>

                        <button
                          className='Sbtn btn btn-outline-secondary'
                          style={{ background: '#230bb3', color: 'white',padding:'5px 6px', borderRadius: '0px', fontSize: "13px",textWrap:'nowrap' }}
                          type="button"
                          id="button-addon2"
                          onClick={() => { nvg('/voucher-request-add') }}
                        >
                          {/* <i className="fa fa-search"></i> */}
                          <span style={{ fontSize: '18px' }}>+</span>&nbsp; Request Voucher
                        </button>
                        </div>
                      </div>
                      {/* <div className="filter-main-btn m-0" style={{display :currentwdith < 730 ? "block" : "none"}} onClick={()=>{setfilter(!filter)}}><span className="filter-btn  "><i className="fa fa-filter" aria-hidden="true" /> Filter</span></div> */}
                      <div className="filter-main-btn m-0" style={{ display: currentwdith < 730 ? "block" : "none" }} >          <button
                        type="button"
                        className="hidecontent my-2"
                        style={{
                          border: "none",
                          outline: "none",
                          backgroundColor: "white",
                        }}
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalfour"
                      >
                        {/* <span className="filter-btn " style={{ fontSize: '12px' }}><i className="fa fa-filter" aria-hidden="true" /> Filter</span> */}
                      </button></div>

                      <div className="table-responsive" >
                        <table className="table  orstatus" >

                          {/* <table className="table"> */}
                          <thead>
                            <tr>

                              <th scope="col" ><div style={{
                                fontWeight: 600, fontSize: "12px", display: "-webkit-box",
                                WebkitLineClamp: "1",
                                textAlign: "center",
                                textWrap: 'nowrap',
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                              }}>S.No</div></th>
                              
                            
 <th scope="col" ><div style={{
                                fontWeight: 600, fontSize: "12px", display: "-webkit-box",
                                WebkitLineClamp: "1",
                                textAlign: "center",
                                textWrap: 'nowrap',
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                              }}>Transaction Id </div></th>
 <th scope="col" ><div style={{
                                fontWeight: 600, fontSize: "12px", display: "-webkit-box",
                                WebkitLineClamp: "1",
                                textAlign: "center",
                                textWrap: 'nowrap',
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                              }}>Transaction Type </div></th>
                             






                              <th scope="col" ><div style={{
                                fontWeight: 600, fontSize: "12px", display: "-webkit-box",
                                WebkitLineClamp: "1",
                                textAlign: "center",
                                textWrap: 'nowrap',
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                              }}>Credit Amount</div></th>
                                 
                              <th scope="col" ><div style={{
                                fontWeight: 600, fontSize: "12px", display: "-webkit-box",
                                WebkitLineClamp: "1",
                                textAlign: "center",
                                textWrap: 'nowrap',
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                              }}> Date</div></th>
                            
                            </tr>
                          </thead>
                          {/* </table> */}



                          <thead className="table-light" style={{ paddingTop: '20px' }}>
                            <tr>

                            </tr>
                          </thead>
                          <tbody >
                            {data[0] ? (data.map((item, index) => (
                              <tr key={index}>


                                <td style={{ color: '#230BB3', paddingTop: '20px', textAlign: "center", fontSize: '12px' }}>

                                  <h5
                                    //  onClick={() => { nvg(/order-history-detail/${item.number}) }}
                                    style={{ fontSize: "12px", lineHeight: "22px", cursor: 'pointer', position: 'relative' }}> {1 + index}</h5>
                                </td>
                                {/* <td style={{ color: '', paddingTop: '20px', textAlign: "center", fontSize: '12px' }}>

                    
                                  {item.voucher_request_name}
                          
                                </td> */}



                                <td style={{ paddingTop: '20px', textAlign: "center", fontSize: '12px' }}>{item.order_number == null ? item.credit_request : item.order_number}</td>
                                <td style={{ paddingTop: '20px', textAlign: "center", fontSize: '12px' }}>{item.credit_used_amount > 0 ? "Credit" : "Debit"}</td>


                                {/* <td style={{ paddingTop: '20px', textAlign: "center", fontSize: '12px', position: 'relative', left: "10px" }}>{item.no_of_vouchers} </td> */}
                                <td style={{ paddingTop: '20px', textAlign: "center", fontSize: '12px',color:item.credit_used_amount > 0 ? "green" : "red" }}>{item.credit_used_amount}</td>
                                

                                <td style={{ paddingTop: '20px', textAlign: "center", fontSize: '12px', }}>
                                   {new Date(item.credit_used_time.split('Time')[0]).toLocaleDateString('en-GB', {
                                  hour: 'numeric',
                                  minute: 'numeric'
                                }


                                )}
                                </td>


                                {/* <td className='d-flex justify-content-center'>
                                  <p style={{ paddingTop: '15px', width: "115px", fontSize: '12px', textAlign: 'center' }}>  &nbsp;      
                                             {item.status.charAt(0).toUpperCase() + item.status.slice(1)}
                                  </p>
                                </td> */}

                              </tr>
                            ))) : (
                              <tr>
                                <td
                                  colSpan={5}
                                  className="text-center"
                                  style={{ fontWeight: "500" }}
                                >
                                  {error}
                                </td>
                              </tr>
                            )}



                          </tbody>
                        </table>
                      </div>

                      <div className="col-xl-12 d-flex justify-content-end convertdesign" >
                    <div className='d-flex align-items-end convertdesign' style={{gap:'4px'}}>
<div style={{display:'flex',gap:'4px'}}>
  <div>
    <label htmlFor="" className='mb-0' >Start Date</label>
                    <input
                    type='date'
                    className="form-control custom-select"
                    placeholder='start date'
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    style={{borderRadius: "0px",fontSize:'13px' }}
                    />
                    </div>
                    <div>
                      <label className='mb-0' htmlFor="">End Date</label>
         <input
                    type='date'
                    className="form-control custom-select"
                    placeholder='end date'
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    style={{borderRadius: "0px",fontSize:'13px' }}
                    />
                    </div>
          </div>
                      {endDate == null || startDate == null ? <button
                                  className='Sbtn btn btn-outline-secondary'
                                  style={{ background: '#230bb3', color: 'white', borderRadius: '0px', fontSize: "13px",textWrap:'nowrap' }}
                                  type="button"
                                  // onClick={handleDownloadPDF}
                                  disabled
                                >
                                  GET STATEMENT
                                </button> : <button
                                  className='Sbtn btn btn-outline-secondary'
                                  style={{ background: '#230bb3', color: 'white', borderRadius: '0px', fontSize: "13px",textWrap:'nowrap' }}
                                  type="button"
                                  onClick={()=>{statement()}}
                                >
                                  GET STATEMENT
                                </button>}
                    </div>
                    </div>
                    <div style={{display:'flex',justifyContent:'end'}}>
                                <span style={{color:'red',paddingTop:'4px'}}>{statementerror}</span>
                    </div>

                    </div>

                  </div>






                </div>
              </div>
            </div>
          </section>

        </div>


        <Footer />
      </div>


    </div>
  )
}
export default Mycredit;