import React, { useEffect, useState } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { NavLink, useNavigate } from 'react-router-dom'
import { AiOutlineEye,AiOutlineHeart,AiOutlineShoppingCart } from "react-icons/ai";
import { MdDelete } from "react-icons/md";
import { MdOutlineDeleteOutline } from "react-icons/md";
import axios from 'axios';
import { gettoken } from '../Localstorage/Store';
import { useDispatch, useSelector } from 'react-redux';
import { addwishlist } from '../store/state/wishlist';

const Wishlist = () => {
const nvg = useNavigate()
const gobalvariable = useSelector(state => state);
const dispatch = useDispatch();


  const [loading, setloading] = useState(false);
  const [msg, setmsg] = useState(0);
  const [data,setData] = useState([]);
  const tokenvalue = gettoken();

  const transfer = (productid,pname) => {
    nvg("/productdetails", {
      state: {
        id: productid,
        pname: pname
        // categoryid: location.state?.id,
        // pagename: location.state?.pagename,
      },
    });
    window.location.reload();
  };


  const removewishlist = async (id) => {
    try {
      setloading(true)
      let url = `${process.env.REACT_APP_API_URL}homepageapi/DeleteProductFromWishlist/${id}/`;
      const response = await axios.delete(url);

      console.log("add to cart response", response);
      if (response.data.message == "success") {
        dispatch(addwishlist(gobalvariable.wishlist - 1));
        fetchcategory()
      setloading(false);
      setmsg(1)
      setTimeout(() => {
        setmsg(0)
      }, 2000);

      }
    } catch (error) {
      console.log("error", error);
      setloading(false)
    }
  };

  async function fetchcategory(){
    try {
    const config = {
      headers: {
        Authorization: `Bearer ${tokenvalue.access}`,
      },
    };
    setloading(true)
    let url = `${process.env.REACT_APP_API_URL}homepageapi/wishlist-deatail/`;
    const response = await axios.get(url,config);
    console.log("first nnn oo",response)
    setData(response.data);
    setloading(false)
  } catch (error) {
    setData([]);
    setloading(false)
  }
  
  }
  useEffect(()=>{
  
    fetchcategory()
      
    },[])
  
  return (
    <>
    <Header />





  {/* breadcrumb start */}
  <div className="breadcrumb-main marginfromtop">
    <div className="container m-0">
      <div className="row">
        <div className="col">
          <div className="breadcrumb-contain">
            <div>
              <ul>
                <li><a href="/home">home</a></li>
                {/* <li>></li> */}
                <li style={{fontSize:"12px"}}>&gt;</li>
                <li><a href="javascript:void(0)">Wishlist</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {msg == 1 ? <div className="container-fluid"><div className="row px-2"><div className=" alert alert-success" role="alert">
  successfully Remove
</div></div></div> : ''}
  
  {/* breadcrumb End */}
  {/* section start */}
  <section className="section-big-pt-space ratio_asos" style={{background:'#f9f9f9',minHeight:"100vh"}}>
    <div className="collection-wrapper">
      <div className="custom-container">
        <div className="row">
          <div className="collection-content col">
            <div className="page-main-content">
              <div className="row">
                <div className="col-sm-12 px-0">
                  {/* <div className="top-banner-wrapper">
                    <NavLink to="/wishlist"><img src="/images/category/1.jpg" className="img-fluid " alt /></NavLink>
                  </div> */}
                  <div className="collection-product-wrapper">
                    <div className="product-top-filter">
                      <div className="row">
                        <div className="col-12">
                          <div className="product-filter-content">
                            {/* <div className="search-count text-start" style={{border: 'none', padding: '20px 0px 7px 0px'}}>
                              <h5 style={{fontWeight: 400, fontSize: 13}}>Showing Products 1-24 of 10 Result</h5></div>
                            <div className="collection-view" style={{width: '100%'}}>
                              <ul>
                                <li style={{fontSize: 13, fontWeight: 600}}>Sort By</li>
                                <li style={{fontSize: 13, marginRight: 12}}>Price - Low to High</li>
                                <li style={{fontSize: 13, marginRight: 12}}>Price - High to Low</li>
                                <li style={{fontSize: 13, marginRight: 12}}>Newest First</li>
                                <li style={{fontSize: 13}}>Discount</li>
                              </ul>
                            </div> */}
                            {/* <div class="collection-view">
                      <ul>
                        <li><i class="fa fa-th grid-layout-view"></i></li>
                        <li><i class="fa fa-list-ul list-layout-view"></i></li>
                      </ul>
                    </div>
                    <div class="collection-grid-view">
                      <ul>
                        <li><img src="/images/category/icon/2.png" alt="" class="product-2-layout-view"></li>
                        <li><img src="/images/category/icon/3.png" alt="" class="product-3-layout-view"></li>
                        <li><img src="/images/category/icon/4.png" alt="" class="product-4-layout-view"></li>
                        <li><img src="/images/category/icon/6.png" alt="" class="product-6-layout-view"></li>
                      </ul>
                    </div>
                    <div class="product-page-per-view">
                      <select>
                        <option value="High to low">24 Products Par Page</option>
                        <option value="Low to High">50 Products Par Page</option>
                        <option value="Low to High">100 Products Par Page</option>
                      </select>
                    </div>
                    <div class="product-page-filter">
                      <select>
                        <option value="High to low">Sorting items</option>
                        <option value="Low to High">50 Products</option>
                        <option value="Low to High">100 Products</option>
                      </select>
                    </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="product-wrapper-grid product">
                      {/* <div className="row"> */}
                      <div className="row removepadding" style={{gap:'4px'}}> 
                      {loading == true ? ("") : data?.[0] ? data.map((item, index) => (
                                <div className=" overridewdith">
                                  <div
                                    className="product-boxwish bg-white catbox"
                                    style={{ margin: "3px 4px" }}
                                  >
                                    <div className="product-imgbox">
                                      <div className="product-front">
                                        <button
                                          type="button"
                                          className="btn fixedhight"
                                          style={{ width: "100%",position:'relative' }}
                                         
                                        >
                                          {" "}
                                          <img
                                            src={`${process.env.REACT_APP_API_URL}media/${item?.product_images?.[0]?.original}`}
                                            className="img-fluid  "
                                            onClick={() => {
                                              transfer(item.product_id,item.title);
                                            }}
                                            alt="product"
                                          />{" "}
                                         <span style={{position:'absolute',right:'12px',top:'8px',display:'inline-block',zIndex:56}} onClick={()=>{removewishlist(item.id)}}> <MdOutlineDeleteOutline size={25} color='#333' /></span>
                                        </button>
                                      </div>
                                    </div>
                                    <div className="product-detail detail-center detail-inverse">
                                      <div className="detail-title">
                                        <div className="detail-left">
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "center",
                                            }}
                                          >
                                            {" "}
                                            <button
                                              type="button"
                                              className="btn"
                                              onClick={() => {
                                                transfer(item.product_id,item.title);
                                              }}
                                            >
                                              <h6
                                                className="price-title catbox2"
                                                style={{
                                                  fontSize: "14px",
                                                  fontWeight: "600",
                                                  color:"#230bb3",
                                                  height:'24px',
                                                  lineHeight:'30px'
                                                }}
                                              >
                                                 {/* {item.title.length > 10 ? item.title.substring(0,18) + '...' : item.title} */}
                                                {item.att_value.filter(item => item.attribute__name.toLowerCase() == "brand").map((item,index)=>(
                                                  item.value_option__option
                                                ))}
                                              </h6>{" "}
                                              <h6
                                                className="price-title"
                                                style={{
                                                  fontSize: "14px",
                                                  fontWeight: "600",
                                                  display: "-webkit-box",
                                                  WebkitLineClamp: "1",
                                                  WebkitBoxOrient: "vertical",
                                                  overflow: "hidden",
                                                }}
                                              >
                                                 {/* {item.title.length > 10 ? item.title.substring(0,18) + '...' : item.title} */}
                                                 {item.title}
                                              
                                              </h6>{" "}
                                            </button>
                                          </div>
                                        </div>
                                        <div
                                          className="detail-right"
                                          style={{ width: "100%" }}
                                        >
                                          <div
                                            className="price"
                                            style={{ width: "100%" }}
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                color: "#000",
                                                fontWeight:'500',
                                                fontSize: "14px",
                                              }}
                                            >
                                              {" "}
                                              {/* ₹{item?.product_price?.excl_tax} */}
                                              ₹{parseInt(item?.stock_record?.[0]?.price)}{" "} {item.stock_record?.[0].discount == 0 ? '' : <span style={{fontSize: '13px',color: '#c1c1c1',lineHeight: '20px',textDecoration: 'line-through',paddingLeft:'3px',fontWeight:'400'}}>₹{item.stock_record?.[0].mrp}</span>}{item.stock_record?.[0].discount == 0 ? '' : <span style={{fontSize: '12px',color: '#230bb3',lineHeight: '20px',paddingLeft:'3px',fontWeight:'400'}}>{item.stock_record?.[0].discount_type == "amount" ? `(₹${item.stock_record?.[0].discount} off)` : `(${item.stock_record?.[0].discount} %off)`}</span>}

                                            </div>
                                            {/* <div className="price text-align-center" style={{display:'flex',justifyContent:'center'}}>  ₹{item.price} </div> */}
                                          </div>
                                        </div>
                                      </div>
                                      {/* <div className="icon-detail">                             
                                <button className="tooltip-top add-cartnoty" onClick={()=>{Addtocart(item.id)}} data-tippy-content="Add to cart"> <AiOutlineShoppingCart size={45}/> </button>
                                <a href="javascript:void(0)" className="add-to-wish tooltip-top" data-tippy-content="Add to Wishlist"> <AiOutlineHeart size={45} /> </a>
                                <a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#quick-view" className="tooltip-top" data-tippy-content="Quick View"> <AiOutlineEye size={45} /> </a>
                                
                              </div> */}
                                    </div>
                                  </div>
                                </div>
                              )) :  <h2
                              style={{
                                textAlign: "center",
                                paddingTop: "17px",
                                fontWeight: "600",
                                fontSize: "20px",
                              }}
                            >
                              Your Wishlist is empty!!
                            </h2>
                            }
                          </div>
                    </div>
                    {/* <div className="product-pagination">
                      <div className="theme-paggination-block">
                        <div className="row">
                          <div className="col-xl-6 col-md-6 col-sm-12">
                            <nav aria-label="Page navigation">
                              <ul className="pagination" style={{margin: 0}}>
                                <li className="page-item"><a className="page-link" href="javascript:void(0)" aria-label="Previous"><span aria-hidden="true"><i className="fa fa-chevron-left" aria-hidden="true" /></span> <span className="sr-only">Previous</span></a></li>
                                <li className="page-item "><a className="page-link" href="javascript:void(0)">1</a></li>
                                <li className="page-item"><a className="page-link" href="javascript:void(0)">2</a></li>
                                <li className="page-item"><a className="page-link" href="javascript:void(0)">3</a></li>
                                <li className="page-item"><a className="page-link" href="javascript:void(0)" aria-label="Next"><span aria-hidden="true"><i className="fa fa-chevron-right" aria-hidden="true" /></span> <span className="sr-only">Next</span></a></li>
                              </ul>
                            </nav>
                          </div>
                          <div className="col-xl-6 col-md-6 col-sm-12">
                            <div className="product-search-count-bottom">
                              <h5 style={{fontSize: 13, color: 'black', fontWeight: 100}}>Showing Products 1-24 of 10 Result</h5></div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* section End */}


    {/* <Footer /> */}
    </>
  )
}

export default Wishlist