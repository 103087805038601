import React, { useEffect, useState } from "react";
// import React from 'react'

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { gettoken } from "../Localstorage/Store";
import Loginheader from "../components/Loginheader";
// import { TfiAlignJustify } from "react-icons/tfi";
// import { useLocation } from "react-router-dom";
export const Termsconditions = () => {
  const tokenvalue = gettoken();
  return (
    <div>
     {tokenvalue ? <Header /> : <Loginheader />} 



      <div>
          {/* breadcrumb start */}
  <div className={tokenvalue ? "breadcrumb-main marginfromtop" : "breadcrumb-main marginfromtop23"} style={{backgroundColor:"#fff"}}>
    <div className="container m-0">
      <div className="row">
        <div className="col">
          <div className="breadcrumb-contain">
            <div>
              <ul>
                <li><a href="/home">home</a></li>
                <li>{">"}</li>
                <li><a href="javascript:void(0)">Terms and Conditions</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* breadcrumb End */}
        <section className="section-big-pt-space pb-2" style={{backgroundColor:"#ececec"}}>
          <div className="col-lg-12 col-sm-12 col-xs-12 mt-lg-3 mt-xs-4 mb-5">
            <div className="container-fuild emp-profile">
              <div className="row profile2">
               

                <section className="tab-product-main-tab">
                  <div className="row mt-2">
                       <h3 className="privacy">TERMS AND CONDITIONS</h3>
                       <p className="policy">Welcome to One Up Brands ("us," "we," or "our"). These Terms and Conditions ("Terms") govern your use of the website oneupbrands.com (the "Website"). Please read these Terms carefully before using our Website. By accessing or using our Website, you agree to be bound by these Terms. If you do not agree with these Terms, please do not use the Website. <br />

Use of the Website <br /><br />

<b>1 Eligibility: </b> <br />

You must be at least 18 years old to use our Website. By using the Website, you represent and warrant that you are at least 18 years old and have the legal capacity to enter into these Terms. <br />
<br />
<b>2 Account Registration: </b> <br />

To access certain features of the Website, you may need to create an account. You agree to provide accurate, current, and complete information during the registration process and to keep your account information updated. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. You must notify us immediately of any unauthorized use of your account. <br />
<br /><b>3 Prohibited Activities: </b> <br />

You agree not to: <br />

Use the Website for any illegal, unauthorized, or fraudulent purposes.Interfere with the proper functioning of the Website or attempt to gain unauthorized access to any portion or feature of
the Website. <br />

Upload or transmit any viruses, malware, or other malicious code.

Violate any applicable laws, regulations, or third-party rights.

Engage in any activity that may harm or disrupt the Website or our services.

Product Listings and Orders <br />
<br />
<b>4 Product Information: </b> <br />

We strive to provide accurate and up-to-date product information on our Website. However, we do not warrant that the product descriptions, images, pricing, or other content on the Website are complete, reliable, or error-free. We reserve the right to correct any errors, inaccuracies, or omissions and to change or update information at any time without prior

notice. <br />
<br />
<b>5 Orders and Payments: </b> <br />

By placing an order through our Website, you make an offer to purchase the products or services selected. We reserve the right to accept any order at our discretion. Upon placing an order, you agree to provide accurate and complete payment information. You authorize us to charge the applicable fees to your selected payment method.
<br />
<br />
<b>6 Shipping and Delivery: </b>
<br />
Shipping and delivery times may vary based on factors beyond our control. We will make reasonable efforts to fulfill and deliver your orders promptly. However, we do not guarantee specific delivery dates or times. Risk of loss and title for products pass to you upon our delivery to the carrier.Intellectual Property
<br />
<br />
<b> 7 Ownership:</b>
<br />
All content and materials available on the Website, including but not limited to text, graphics, logos, images, audio clips, videos, and software, are the property of One Up Brands or its licensors and are protected by applicable intellectual property laws. You may not use, reproduce, modify, distribute, or display any portion of the Website without our prior

written consent.
<br />
<br />
<b>8 Trademarks:</b>
<br />
One Up Brands, our logo, and other trademarks, service marks, or trade names used on the Website are owned by One Up Brands. You may not use any of these marks without our prior written permission.Disclaimers and Limitations of Liability
<br />
<br />
</p>

                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
        <Footer />
        {/* </div> */}

        {/* <Footer /> */}

        {/* footer start */}
     
      </div>
    </div>
  );
};
export default Termsconditions;