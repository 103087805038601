import React, { useEffect, useRef, useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { gettoken } from "../Localstorage/Store";
import { useFormik } from "formik";
import { Field, Form, Formik } from "formik";


import Loginheader from "../components/Loginheader";
import axios from "axios";
import { business } from "../Validation/business";
import Multiselect from "multiselect-react-dropdown";
import { vrequest } from "../Validation/vrequest";
import { useNavigate } from "react-router-dom";
import { crequest } from "../Validation/crequest";
import ChangePasswordSchema from "../Validation/Changepassword";
const Changeyourpassword = () => {
  const tokenvalue = gettoken();
  const [loading, setloading] = useState(false);
  const [success, setsuccess] = useState("");
  const [cityna, setcityna] = useState("");
  const [option, setoption] = useState([]);
  const [selectedcate, setselectedcate] = useState([]);
  const multiselectRef = useRef(null);
  const nvg = useNavigate();
  const vrequestfrom = async (values,setFieldError,action) => {
console.log("values of fiedl",values);
setloading(true)
    try {


      let data = {
          old_password:values.oldPassword,
          new_password:values.newPassword
        };
        const config = {
          headers: {
            Authorization: `Bearer ${tokenvalue.access}`,
          },
        };
     
      let url = `${process.env.REACT_APP_API_URL}useraccount/change_password`;
      const response = await axios.post(url,data,config);
      console.log("sssssssssssssssssssss", response);
     if(response.data.message == "password changed"){
      setsuccess("Password Changed Successfully")
     }
      action();

      setloading(false);
      // setTimeout(() => {
      //   nvg('/creditrequest')
      // }, 5000);
  
     
      setTimeout(() => {
        setsuccess("");
      }, 3000);
    } catch (error) {
      // if(error.response.data){
      if(error.response.data.message){
        setFieldError("oldPassword",error.response.data.message)
      }
      // }
      setloading(false)
      console.log("this ier eror msf",error.response.data)
    }
  };
  


  



  return (
    <>
      {tokenvalue ? <Header /> : <Loginheader />}

      <div
        className={
          tokenvalue
            ? "breadcrumb-main marginfromtop"
            : "breadcrumb-main marginfromtop23"
        }
      >
        {/* <div className="breadcrumb-main marginfromtop"> */}
        <div className="container m-0">
          <div className="row">
            {/* {console.log("dkkkkddkmmccmcmcmc",values,"akkderrorr",errors)} */}
            <div className="col">
              <div className="breadcrumb-contain">
                <div>
                  <ul>
                    <li>
                      <a href="/home">home</a>
                    </li>
                    <li>{`>`}</li>
                    <li>
                      <a href="javascript:void(0)">Change Password</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="contact-page section-big-py-space b-g-light">
        <div className="custom-container">
          <div className="row section-big-pb-space">
            <div className="col-xl-6 offset-xl-3">
              <h3 className="text-center mb-3" style={{ fontSize: "23px" }}>
              CHANGE PASSWORD
              </h3>
              {/* {console.log("values of value",values,"error of erro",errors)} */}











              <Formik
                      initialValues={{
                        oldPassword: "",
                        newPassword: "",
                        confirmPassword: "",
                      }}
                      validationSchema={ChangePasswordSchema}
                      onSubmit={(values, { setFieldValue,setFieldError,resetForm }) => {

                        vrequestfrom(values,setFieldError,resetForm)
                      }}
                    >
                      {({
                        values,
                        errors,
                        handleSubmit,
                        touched,
                        setFieldValue,
                        setFieldError,
                        dirty,
                        isValid,
                      }) => (
                        <Form
                          autoComplete="off"
                          className={`theme-form container-fluid mt-3`}
                          style={{ width: "100%",height:'fit-content' }}
                          onSubmit={handleSubmit}
                        >
                          <div className="row px-2">
  





                            <div className="col-md-12">
                    <div className="form-group">
                      <label className="acounttitle" style={{marginBottom:'3px'}} htmlFor="email">
                        
                          Old Password <span style={{ color: "red" }}>*</span>
                      </label>
                     
{/* {console.log("latest error ",errors)} */}
<Field
                                 type="password"
                                 className="form-control"
                                 name="oldPassword"
                                 style={{ fontSize: "12px",height:'40px' }}
                                 // onChange={(e)=>{setFieldValue("oldPassword",e.target.value)}}
                                 id="last-name"
                                 placeholder="Old Password"
                                 value={values.oldPassword}
                                />
                      
                      {errors.oldPassword && touched.oldPassword ? (
                        <p className="form-group" style={{ color: "red" }}>
                          {errors.oldPassword}
                        </p>
                      ) : null}
                    </div>
                  </div>
                                  <div className="col-md-12">
                    <div className="form-group">
                      <label className="acounttitle" style={{marginBottom:'3px'}} htmlFor="email">
                        New Password <span style={{ color: "red" }}>*</span>
                      </label>


                      <Field      type="password"
                                  className="form-control"
                                  name="newPassword"
                                  style={{ fontSize: "12px",height:'40px' }}
                                  // onChange={(e)=>{setFieldValue("newPassword",e.target.value)}}
                                  id="last-name"
                                  placeholder="New Password"
                                  value={values.newPassword}
                                />
                    
                      {errors.newPassword && touched.newPassword ? (
                        <p className="form-group" style={{ color: "red" }}>
                          {errors.newPassword}
                        </p>
                      ) : null}
                    </div>
                  </div>
                                  <div className="col-md-12">
                    <div className="form-group">
                      <label className="acounttitle" style={{marginBottom:'3px'}} htmlFor="email">
                        Confirm Password <span style={{ color: "red" }}>*</span>
                      </label>

                      <Field
                                 type="password"
                                 className="form-control"
                                 name="confirmPassword"
                                 style={{ fontSize: "12px",height:'40px' }}
                                 // onChange={(e)=>{setFieldValue/("confirmPassword",e.target.value)}}
                                 id="last-name"
                                 placeholder="Confirm Password"
                                 value={values.confirmPassword}
                                />
             
                      {errors.confirmPassword && touched.confirmPassword ? (
                        <p className="form-group" style={{ color: "red" }}>
                          {errors.confirmPassword}
                        </p>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-md-12 d-flex ">
                    <button className="btn btn-normal" type="submit">
                      {" "}
                      CHANGE PASSWORD
                    </button>
                  </div>

                  <div
                    className="col-md-12 pt-2"
                    style={{ display: success == "" ? "none" : "block" }}
                  >
                    <div class="alert alert-success" role="alert">
                      {success}
                    </div>
                  </div>



                         
                          </div>
                        </Form>
                      )}
                    </Formik>










{/* 
              <form className="theme-form" onSubmit={handleSubmit}>
                <div className="row">
             
                        <div className="col-md-12">
                    <div className="form-group">
                      <label className="acounttitle" htmlFor="email">
                        Old Password <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        name="oldPassword"
                        style={{ fontSize: "12px" }}
                        id="last-name"
                        placeholder="Old Password"
                        value={values.oldPassword}
                      
                      />
                      
                      {errors.oldPassword && touched.oldPassword ? (
                        <p className="form-group" style={{ color: "red" }}>
                          {errors.oldPassword}
                        </p>
                      ) : null}
                    </div>
                  </div>
                                  <div className="col-md-12">
                    <div className="form-group">
                      <label className="acounttitle" htmlFor="email">
                        New Password <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        name="newPassword"
                        style={{ fontSize: "12px" }}
                        id="last-name"
                        placeholder="New Password"
                        value={values.newPassword}
                      
                      />
                      {errors.newPassword && touched.newPassword ? (
                        <p className="form-group" style={{ color: "red" }}>
                          {errors.newPassword}
                        </p>
                      ) : null}
                    </div>
                  </div>
                                  <div className="col-md-12">
                    <div className="form-group">
                      <label className="acounttitle" htmlFor="email">
                        Confirm Password <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        name="confirmPassword"
                        style={{ fontSize: "12px" }}
                        id="last-name"
                        placeholder="Confirm Password"
                        value={values.confirmPassword}
                     
                      />
                      {errors.confirmPassword && touched.confirmPassword ? (
                        <p className="form-group" style={{ color: "red" }}>
                          {errors.confirmPassword}
                        </p>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-md-12 ">
                    <button className="btn btn-normal" type="submit">
                      {" "}
                      CHANGE PASSWORD
                    </button>
                  </div>
                  <div
                    className="col-md-12 pt-2"
                    style={{ display: success == "" ? "none" : "block" }}
                  >
                    <div class="alert alert-success" role="alert">
                      {success}
                    </div>
                  </div>
                </div>
              </form> */}
            </div>
          </div>
         
        </div>
      </section>

      <Footer/>
    </>
  );
};
export default Changeyourpassword;