
import { Field, Form, Formik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import { Register5val } from "../Validation/register5val";
import { useEffect, useState } from "react";
import axios from "axios";
import { gettoken } from "../Localstorage/Store";

const Register1part2 = () => {


  const [currentStep, setCurrentStep] = useState(3);
  const [statusstep5, setstatusstep5] = useState("");
  const [sub, setsub] = useState(0);
  const [loader4, setloader4] = useState(true);
  const [checkbox, setcheckbox] = useState(false);
  const [gsterror, setgsterror] = useState('');
  const [panerror, setpanerror] = useState('');
  const [adhaarerror, setadhaarerror] = useState('');
  const [certificate_of_corporation, setcertificate_of_corporation] = useState('');
  const [msme, setmsme] = useState('');
  const [authorization_letter, setauthorization_letter] = useState('');

  const [termandcondition, settermandcondition] = useState(false);
  const location = useLocation()

const tokenvalue = gettoken();
  useEffect(()=>{
    if(tokenvalue){
        nvg('/home')
    }
  },[])

  console.log("checking value",termandcondition)


  // console.log("this is location testing",location)
  const setgstaddress = (status,setFieldValue,step1id) =>{
    if(status == false){
      // setcheckbox(true)
    console.log("qwertyuio11111rrrrp",step1id)
      setFieldValue("billingaddress",`${step1id.first_address} ${step1id.second_address} ${step1id.country} ${step1id.state} ${step1id.city} ${step1id.postal_code}`);
    
    }else{
      // setcheckbox(false)
      setFieldValue("billingaddress",'');
    }
      }
  const fourthform = async(values,setstatusstep5) =>{

    setloader4(false)
    


    const formdata = new FormData()

    let jsonData = {
      business: {
          primary_contact_person:location.state.values.contactperson,
          designation: location.state.values.designation,
          email: location.state.values.email,
          mobile_number: `+91${location.state.values.mobile}`,
          bussiness_name: location.state.values.companyname
      },
      gst: {
          gst_number:location.state.values.GSTIN == "" ? null : location.state.values.GSTIN,
          first_address: location.state.values.address,
          second_address: location.state.values.address2,
          postal_code: location.state.values.postalcode,
          city: location.state.values.city,
          state: location.state.values.state,
          country: location.state.values.country,
          date_of_establishment: location.state.values.registrationdate,
          pancard_no: location.state.values.pan
      },
      bussiness_details: {
          first_company_type: location.state.values.companytype,
          second_company_type: location.state.values.companytype2,
          industry_type: location.state.values.industrytype,
          website_link: location.state.values.weblink
      },
      alt_person: {
          alternative_person_name: location.state.values.alt_name,
          alternative_designation: location.state.values.alt_des,
          alternative_email: location.state.values.alt_email,
          alternative_mobile: `+91${location.state.values.alt_mobile}`
      },
      
      comm: {
          communication_postal_code: location.state.values.office_postalcode,
          communication_country: location.state.values.office_country,
          communication_state: location.state.values.office_state,
          communication_city: location.state.values.office_city,
          communication_address:location.state.values.office_address
      },
      reg: {
          reg_postal_code: location.state.values.reg_postalcode,
          reg_country: location.state.values.reg_country,
          reg_state: location.state.values.reg_state,
          reg_city: location.state.values.reg_city,
          reg_address:location.state.values.reg_address
      }
  };



    if(values.pan != null){
      formdata.append('upload_pan',values.pan)
    }

    if(values.gst != null){
      formdata.append('upload_gst',values.gst)
    }

    if(values.certificateofcorporation != null){
      formdata.append('certificate_of_corporation',values.certificateofcorporation)
    }
    
    if(values.msme != null){
      formdata.append('msme',values.msme)
    }

    if(values.cancelledcheque != null){
      formdata.append('aadhar',values.cancelledcheque)
    }

    if(values.authorizationletter != null){
      formdata.append('authorization_letter',values.authorizationletter)
    }
        formdata.append('json_data', JSON.stringify(jsonData));

        if(sub == 0){
          try {
            let url = `${process.env.REACT_APP_API_URL}useraccount/Onboarding_final`;
            setsub(1)
          const response = await axios.post(url, formdata);
          // console.log("respo of bussiness",response);
          setstatusstep5(response.data.message)
          setloader4(true)
          setstatusstep5(response.data.message)
      setTimeout(() => {
        nvg('/thankyoupageforonboarding')
      }, 2000);
          } catch (error) {
            setsub(0)
            console.log("this is error msg",error.response)
            if(error.response.data.aadhar){
              setadhaarerror(error.response.data.aadhar[0])
            }
            if(error.response.data.upload_pan){
              setpanerror(error.response.data.upload_pan[0])
            }
            if(error.response.data.upload_gst){
              setgsterror(error.response.data.upload_gst[0])
            }
            if(error.response.data.certificate_of_corporation){
              setcertificate_of_corporation(error.response.data.certificate_of_corporation[0])
            }
            if(error.response.data.msme){
              setmsme(error.response.data.msme[0])
            }
            if(error.response.data.authorization_letter){
              setauthorization_letter(error.response.data.authorization_letter[0])
            }

          }
        }else{
          console.log("first")
          // setsub(0)
        }
        
        }

        




const [documents,setdocuments] = useState('')



  const nvg = useNavigate();



useEffect(()=>{
if(location){
  if(location.state.values.GSTIN == ""){
    setdocuments("pan,adhaar")
  }else{
if(location.state.values.companytype == "Proprietary"){
  setdocuments("pan,adhaar,gst,msme")
}else{
  if(location.state.values.companytype == "Partnership"){
    setdocuments("pan,adhaar,gst,msme,register,authorization")
  }else{
    setdocuments("pan,gst,msme,authorization,corporation")
  }
}
  }
}
},[])



  return (
    <div>
      <section
        className="section-big-pt-space pb-2"
        style={{ backgroundColor: "rgb(249 249 249)" }}
      >
        <div className="col-lg-12 col-sm-12 col-xs-12 mt-lg-3 mt-xs-4">
          <div className="container-fuild emp-profile">
            <div
              className="row profileregister"
              style={{
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              }}
            >
              <section className="tab-product-main-tab">
                <div className="row mt-5">
                  <div
                    id="tab-3"
                    style={{ display: "block" }}
                    className="tab-content active default product-block3"
                  >
                    <div
                      className="d-flex"
                      style={{ justifyContent: "center" }}
                    >
                      <div className="card p-1 " style={{ border: "none" }}>
                        <img
                            onClick={()=>{nvg('/')}}
                          // src="./images/login-logo.png"
                          src={`${process.env.REACT_APP_API_PUBLIC_URL}/images/logo.png`}
                          className="login-tabs-img customwidth"
                          style={{ marginBottom: "0px", cursor: "pointer" }}
                          alt
                        />

                        {/* <h2 style={{ lineHeight: "49px", fontWeight: "600" }}>
                          <span style={{ color: "#230BB3" }}> Welcome </span>{" "}
                          <span style={{ color: "#2B2A29" }}>And Sign Up</span>
                        </h2> */}
                      </div>
                    </div>
                    <Formik
              initialValues={{
                // bankname: "",
                // bankaccountnumber: "",
                // billingaddress: "",
                // bankaddress: "",
                // ifsc: "",
                // terms:false,
                gst:null,
                pan:null,
                cancelledcheque:null,
                authorizationletter:null,
                msme:null,
                certificateofcorporation:null
              }}
              // validationSchema={Register5val}
              onSubmit={(values, { setFieldValue }) => {
                // console.log("this is formik ", values);
                fourthform(values,setstatusstep5)
              }}
            >
              {({
                values,
                errors,
                handleSubmit,
                touched,
                setFieldValue,
                dirty,
                isValid,
              }) => (
                <Form
                  autoComplete="off"
                  className={`form-login container-fluid mt-2`}
                  style={{ width: "100%", minHeight: "100vh", height: "auto" }}
                  onSubmit={handleSubmit}
                >

                  {/* {console.log("this is form value",values)} */}
                  <div className="row px-4">
                   
               
                     {/* <div className="col-xl-6 col-md-12 d-flex align-items-center" style={{gap:'4px',fontSize:'14px',fontWeight:'500'}} >
             
                     </div> */}
                  {/* <div className="col-xl-6 col-md-12">
                      <label
                        style={{ margin: "0px", paddingTop: "8px",display:'flex',justifyContent:'space-between',paddingBottom:'12px' }}
                        htmlFor="text"
                      >
                        <span>
                        Billing Address<span style={{color:'red'}}>*</span>
                        </span>



                        <span style={{fontSize:'13px'}}>
                        {checkbox == false ? <input type="checkbox" name="" id="" onChange={()=>{setcheckbox(!checkbox);setgstaddress(checkbox,setFieldValue)}} /> : <input type="checkbox" onChange={()=>{setcheckbox(!checkbox);setgstaddress(checkbox,setFieldValue)}} checked name="" id="" /> }  
                        Same As GST Address
                        </span>
                      </label>
                      <div className="log-sendotp">
                        <Field
                          type="text"
                          name="billingaddress"
                          className="login-input mb-0"
                          placeholder={`Enter Billing Address`}
                          value={values.billingaddress}
                          style={{
                            borderBottom:
                              errors.billingaddress && touched.billingaddress
                                ? "1px solid red"
                                : "",
                          }}
                          onChange={(e) => {
                            // setverifystatus_gst(0);
                            setFieldValue("billingaddress", e.target.value);
                          }}
                        />
                      </div>
                      <span
                        style={{
                          display: "block",
                          marginBottom: "16px",
                        }}
                      >
                        {errors.billingaddress && touched.billingaddress ? (
                          <p style={{ color: "red" }}>{errors.billingaddress}</p>
                        ) : null}
                      </span>
                    
                    </div>  */}


                    <div className="col-xl-6 col-md-12">
              <label style={{ margin: "0px", paddingTop: "8px",paddingBottom:'12px' }} htmlFor="profileImage">Upload PAN Card<span style={{color:'red'}}>*</span></label>
              <div className="log-sendotp">
                <input
                  type="file"
                  accept="image/*"
                  name="pan"
                  className="login-input mb-0 fileinput"
                  style={{ borderBottom: errors.pan && touched.pan ? "1px solid red" : "" }}
                  onChange={(event) => {
                    setFieldValue("pan", event.currentTarget.files[0]);
                  }}
                />
              </div>
              <span style={{ display: "block", marginBottom: "16px" }}>
                {/* {errors.pan && touched.pan && <p style={{ color: "red" }}>{errors.pan}</p>} */}
               {panerror == '' ? '' : <p style={{ color: "red" }}>{panerror}</p>}

              </span>
            </div>

           {documents.includes('gst') ? <div className="col-xl-6 col-md-12">
              <label style={{ margin: "0px", paddingTop: "8px",paddingBottom:'12px' }} htmlFor="GST">Upload GSTIN Certificate <span style={{color:'red'}}>*</span></label>
              <div className="log-sendotp">
                <input
                  type="file"
                  accept="image/*"
                  name="gst"
                  className="login-input mb-0 fileinput"
                  style={{ borderBottom: errors.gst && touched.gst ? "1px solid red" : "" }}
                  onChange={(event) => {
                    setFieldValue("gst", event.currentTarget.files[0]);
                  }}
                />
              </div>
              <span style={{ display: "block", marginBottom: "16px" }}>
                {/* {errors.gst && touched.gst && <p style={{ color: "red" }}>{errors.gst}</p>} */}
                {gsterror == '' ? '' :<p style={{ color: "red" }}> {gsterror}</p>}
              </span>
              </div> : ''}

              {documents.includes('corporation') ? <div className="col-xl-6 col-md-12">
              <label style={{ margin: "0px", paddingTop: "8px",paddingBottom:'12px' }} htmlFor="certificateofcorporation">Upload Certificate of Incorporation <span style={{color:'red'}}>*</span></label>
              <div className="log-sendotp">
                <input
                  type="file"
                  accept="image/*"
                  name="certificateofcorporation"
                  className="login-input mb-0 fileinput"
                  style={{ borderBottom: errors.certificateofcorporation && touched.certificateofcorporation ? "1px solid red" : "" }}
                  onChange={(event) => {
                    setFieldValue("certificateofcorporation", event.currentTarget.files[0]);
                  }}
                />
              </div>
              <span style={{ display: "block", marginBottom: "16px" }}>
                {/* {errors.certificateofcorporation && touched.certificateofcorporation && <p style={{ color: "red" }}>{errors.certificateofcorporation}</p>} */}
                {certificate_of_corporation == '' ? '' :<p style={{ color: "red" }}> {certificate_of_corporation}</p>}
              </span>
              </div>: ''}


              {documents.includes('authorization') ? <div className="col-xl-6 col-md-12">
              <label style={{ margin: "0px", paddingTop: "8px",paddingBottom:'12px' }} htmlFor="GST">Upload Authorization Letter for Account User<span style={{color:'red'}}>*</span></label>
              <div className="log-sendotp">
                <input
                  type="file"
                  accept="image/*"
                  name="authorizationletter"
                  className="login-input mb-0 fileinput"
                  style={{ borderBottom: errors.authorizationletter && touched.authorizationletter ? "1px solid red" : "" }}
                  onChange={(event) => {
                    setFieldValue("authorizationletter", event.currentTarget.files[0]);
                  }}
                />
              </div>
              <span style={{ display: "block", marginBottom: "16px" }}>
                {/* {errors.authorizationletter && touched.authorizationletter && <p style={{ color: "red" }}>{errors.authorizationletter}</p>} */}
                {authorization_letter == '' ? '' :<p style={{ color: "red" }}> {authorization_letter}</p>}
              </span>
              </div> : ''}

              {documents.includes('msme') ? <div className="col-xl-6 col-md-12">
              <label style={{ margin: "0px", paddingTop: "8px",paddingBottom:'12px' }} htmlFor="GST">Upload MSME Certificate <span style={{color:'red'}}>*</span></label>
              <div className="log-sendotp">
                <input
                  type="file"
                  accept="image/*"
                  name="msme"
                  className="login-input mb-0 fileinput"
                  style={{ borderBottom: errors.msme && touched.msme ? "1px solid red" : "" }}
                  onChange={(event) => {
                    setFieldValue("msme", event.currentTarget.files[0]);
                  }}
                />
              </div>
              <span style={{ display: "block", marginBottom: "16px" }}>
                {/* {errors.msme && touched.msme && <p style={{ color: "red" }}>{errors.msme}</p>} */}
{msme == '' ? '' :<p style={{ color: "red" }}> {msme}</p>}
              </span>
              </div> : ''}
              {documents.includes('adhaar') ? <div className="col-xl-6 col-md-12">
              <label style={{ margin: "0px", paddingTop: "8px",paddingBottom:'12px' }} htmlFor="GST">Upload Aadhar Card <span style={{color:'red'}}>*</span></label>
              <div className="log-sendotp">
                <input
                  type="file"
                  accept="image/*"
                  name="cancelledcheque"
                  className="login-input mb-0 fileinput"
                  style={{ borderBottom: errors.cancelledcheque && touched.cancelledcheque ? "1px solid red" : "" }}
                  onChange={(event) => {
                    setFieldValue("cancelledcheque", event.currentTarget.files[0]);
                  }}
                />
              </div>
              <span style={{ display: "block", marginBottom: "16px" }}>
                {/* {errors.cancelledcheque && touched.cancelledcheque && <p style={{ color: "red" }}>{errors.cancelledcheque}</p>} */}
{adhaarerror == '' ? '' :<p style={{ color: "red" }}> {adhaarerror}</p>}
              </span>
              </div> : ''}
  <div className={`col-12 mt-2 mb-3`}>
                           {termandcondition == true ? <Field type="checkbox" name="terms" value={true} checked onChange={()=>{settermandcondition(!termandcondition)}} style={{position:'relative',top:'1px',left:'-4px'}}  /> : <Field type="checkbox" name="terms" value={false} onChange={()=>{settermandcondition(!termandcondition)}} style={{position:'relative',top:'1px',left:'-4px'}}  />}   
                            I agree to <span style={{color:'#230BB3',cursor:'pointer'}} onClick={()=>{nvg("/useragreement")}}> User Agreement </span> and <span style={{color:'#230BB3',cursor:'pointer'}} onClick={()=>{nvg("/privacypolicy")}}> Privacy Policy
                            </span>                            </div>


                  {/* <div className="col-xl-12 col-md-12">
                  <Field type="checkbox" name="terms" style={{position:'relative',top:'1px',left:'-4px'}}  />
                  Same as Company Address
</div> */}
{statusstep5 == "" ? "" : <div className="col-xl-12">
<div class="alert alert-success mt-2" role="alert">
  {statusstep5}
</div>
</div>}

                  
                   <div className="col-xl-12 col-md-12 d-flex justify-content-end py-4" style={{gap:'5px'}}>
                   {location.state.values.GSTIN == "" ? values.pan == null || values.cancelledcheque == null || termandcondition == false ?  <button
                        type="submit"
                        // disabled={!(isValid && dirty)}
                        disabled
                        className="btn "
                        id="myForm"
                        style={{padding:'6px 9px',width:'fit-content'}}
                      >
                        Submit
                        <span>
                          {/* <FaArrowRightLong color="white" /> */}
                        </span>
                      </button> : <button
                        type="submit"
                        // disabled={!(isValid && dirty)}
                        // disabled
                        className="btn "
                        id="myForm"
                        style={{padding:'6px 9px',width:'fit-content'}}
                      >
                        Submit
                        <span>
                          {/* <FaArrowRightLong color="white" /> */}
                        </span>
                      </button> : '' }


                      
                   {location.state.values.GSTIN != "" ? location.state.values.companytype == "Proprietary" ? values.pan == null || values.cancelledcheque == null || values.gst == null || values.msme == null || termandcondition == false ? <button
                        type="submit"
                        // disabled={!(isValid && dirty)}
                        disabled
                        className="btn "
                        id="myForm"
                        style={{padding:'6px 9px',width:'fit-content'}}
                      >
                        Submit
                        <span>
                          {/* <FaArrowRightLong color="white" /> */}
                        </span>
                      </button> : <button
                        type="submit"
                        // disabled={!(isValid && dirty)}
                        className="btn "
                        id="myForm"
                        style={{padding:'6px 9px',width:'fit-content'}}
                      >
                        Submit
                        <span>
                          {/* <FaArrowRightLong color="white" /> */}
                        </span>
                      </button> : location.state.values.companytype == "Partnership" ? values.pan == null || values.cancelledcheque == null || values.gst == null || values.msme == null || values.authorizationletter == null || termandcondition == false ? <button
                        type="submit"
                        // disabled={!(isValid && dirty)}
                        disabled
                        className="btn "
                        id="myForm"
                        style={{padding:'6px 9px',width:'fit-content'}}
                      >
                        Submit
                        <span>
                          {/* <FaArrowRightLong color="white" /> */}
                        </span>
                      </button> : <button
                        type="submit"
                        // disabled={!(isValid && dirty)}
                        className="btn "
                        id="myForm"
                        style={{padding:'6px 9px',width:'fit-content'}}
                      >
                        Submit
                        <span>
                          {/* <FaArrowRightLong color="white" /> */}
                        </span>
                      </button> : values.pan == null || values.gst == null || values.msme == null || values.authorizationletter == null || values.certificateofcorporation == null || termandcondition == false ? <button
                        type="submit"
                        // disabled={!(isValid && dirty)}
                        disabled
                        className="btn "
                        id="myForm"
                        style={{padding:'6px 9px',width:'fit-content'}}
                      >
                        Submit
                        <span>
                          {/* <FaArrowRightLong color="white" /> */}
                        </span>
                      </button> : <button
                        type="submit"
                        // disabled={!(isValid && dirty)}
                        // disabled
                        className="btn "
                        id="myForm"
                        style={{padding:'6px 9px',width:'fit-content'}}
                      >
                        Submit
                        <span>
                          {/* <FaArrowRightLong color="white" /> */}
                        </span>
                      </button> : '' }

   
                      {/* <button
                        type="submit"
                   
                        className="btn "
                        id="myForm"
                        style={{padding:'6px 9px',width:'fit-content'}}
                      >
                        Submit
                        <span>
                     
                        </span>
                      </button> */}
                    </div> 
                   
                  </div>
                </Form>
              )}
            </Formik>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>

      <div
          className="modal fade"
          id="exampleModa1123456"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered ">
            <div className="modal-content">
              {/* <span className="question-logo" style={{display:"flex",justifyContent:"center",alignItems:'center'}} ></span> */}
              {/* <i class="fa fa-exclamation" aria-hidden="true"></i> */}
              <div className="modal-header mod-line d-none"></div>
              <div className="modal-body">
                <div className="row gy-3 mt-2">
                  {/* <div className="d-flex justify-content-center">
          <BsQuestionLg className='question-logo' />
            </div> */}
                  <h4
                    style={{
                      color: "rgb(35, 11, 179)",
                      cursor: "pointer",
                      textAlign: "center",
                      fontSize: "21px",
                      fontWeight: "800",
                    }}
                  >
                    SIGN UP INFO
                  </h4>
                  <p
                    className="ccedit-p "
                    style={{ textAlign: "center", fontSize: "13px" }}
                  >
                    You Have Already Sign Up In Our Website.
                  </p>
                </div>
              </div>
              <div
                className="modal-footer mod-line m-auto"
                style={{ border: "none" }}
              >
                {/* <button type="button" className="btn closecancel" data-bs-dismiss="modal"
                aria-label="Close">Cancel</button> */}
                {/* <button
                  type="button"
                  className="btn closebtn "
                  style={{
                    paddingRight: "20px",
                    fontSize: "12px",
                    fontWeight: "600",
                    color: "#230BB3",
                  }}
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn closebtn profile-edit-btn justhoverwh"
                  data-bs-dismiss="modal"
                  onClick={() => {nvg('/checkout')}}
                  style={{
                    fontSize: "12px",
                    fontWeight: "600",
                  }}
                >
                  Checkout
                </button> */}
              </div>
            </div>
          </div>
        </div>
        {/* <div style={{width:'100%',overflow:'hidden'}}>
        <button
                 ref={openalert}
                                                  type="button"
                                                  style={{ fontSize: "12px" ,position:'absolute',left:'0px'}}
                                                  data-bs-toggle="modal"
                                                  data-bs-target="#exampleModa1123456"
                                                  class="dropdown-item"
                                                                                                  >
                                        
                                               
                                                </button>
                                                </div> */}
    </div>
  );
};

export default Register1part2;

