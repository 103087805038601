import React, { useEffect, useRef, useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { gettoken } from "../Localstorage/Store";
import { useFormik } from "formik";

import Loginheader from "../components/Loginheader";
import axios from "axios";
import { business } from "../Validation/business";
import Multiselect from "multiselect-react-dropdown";
import { vrequest } from "../Validation/vrequest";
import { useNavigate } from "react-router-dom";
const Voucherrequest = () => {
  const tokenvalue = gettoken();
  const [loading, setloading] = useState(false);
  const [success, setsuccess] = useState("");
  const [cityna, setcityna] = useState("");
  const [option, setoption] = useState([]);
  const [selectedcate, setselectedcate] = useState([]);
  const [showoptions, setshowoptions] = useState(false);
  // const options = ['Option 1', 'Option 2', 'Option 3', 'Option 4'];
  const multiselectRef = useRef(null);
  const nvg = useNavigate();
  const vrequestfrom = async (values, action, setFieldError) => {
console.log("values of fiedl",values);
    try {
      let data;
      if(values.categories[0].id == '00'){
         data = {
          user: tokenvalue.id,
          // voucher_request_name:values.vouchername,
          select_all_categories: true,
          voucher_type: values.vouchertype,
           amount: values.amount,
          no_of_vouchers: values.quantity,
          shipping_type: values.Shipping,
          type_of_price: values.valuetype,
          gst_type: values.GST,
          club_type: values.Clubbable,
        };
      }else{
        const extractedIds = values.categories.map(item => item.id)
       data = {
        user: tokenvalue.id,
        // voucher_request_name:values.vouchername,
        select_categories: extractedIds,
        select_all_categories: false,
        voucher_type: values.vouchertype,
         amount: values.amount,
        no_of_vouchers: values.quantity,
        shipping_type: values.Shipping,
        type_of_price: values.valuetype,
        gst_type: values.GST,
        club_type: values.Clubbable,
      };
      }
      let url = `${process.env.REACT_APP_API_URL}bannermanage/voucher-request-user/`;
      const response = await axios.post(url, data);
      console.log("sssssssssssssssssssss ccc", response);
      action();
      const requestId = response.data.uuid;  // Extract the UUID from the response
      setsuccess(`Request sent successfully. Your request ID is: ${requestId}`);
      setTimeout(() => {
        nvg('/mycredit')
      }, 5000);
      if (multiselectRef.current) {
        multiselectRef.current.resetSelectedValues();
      }
     
      setloading(false);
      setTimeout(() => {
        setsuccess("");
      }, 3000);
    } catch (error) {
      console.log("this ier eror msf",error)
    }
  };


  const changeotherfield = (val,setFieldValue) =>{
    if(val == "Gift Voucher"){
      setFieldValue("Clubbable","Clubbable")
      setFieldValue("GST","GST Included")
      setFieldValue("Shipping","Shipping Payable")
      setFieldValue("valuetype","Fixed")
    }
    if(val == "Shopping Voucher"){
      setFieldValue("Clubbable","Clubbable")
      setFieldValue("GST","GST Included")
      setFieldValue("Shipping","Shipping Included")
      setFieldValue("valuetype","Fixed")
    }
    if(val == "Discount Voucher"){
      setFieldValue("Clubbable","NonClubbable")
      setFieldValue("GST","GST Payable")
      setFieldValue("Shipping","Shipping Payable")
      setFieldValue("valuetype","Percentage")
    }
  }
  const initialValues = {
    // Clubbable: "",
    // GST:"",
    // Shipping:"",
    categories: '',
    // vouchertype: "Gift Voucher - Inclusive",
    quantity: "",
    amount: "",
    // valuetype: "",
  };

  const {
    values,
    errors,
    touched,
    handleSubmit,
    setFieldError,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: vrequest,
    onSubmit: (values, { setFieldError, resetForm }) => {
      console.log("vialdldldld now", values);
      if(values.amount > 1000){
        vrequestfrom(values, resetForm, setFieldError);
      }else{
        setFieldError("amount"," Amount Must be higher than 1000")
      }
    },
  });

const [seletedoption,setseletedoption] = useState([{category_bread_crump:"All",id:"00"}])
  async function fetchcategory(check1=0){
    try {
    setloading(true)
    let url = `${process.env.REACT_APP_API_URL}discounts/category_list/`;
    const response = await axios.get(url);
    // console.log("first nnn oo",response)
    if(check1 == 0){
      const appenddata = [{category_bread_crump:"All",id:"00"},...response.data.category_list];
      setoption(appenddata)
    }else{
      setoption(response.data.category_list)
    }
//   response.data.category_list.map((item,index)=>{
// setoption([...option,item.id])
//   })

    setloading(false)
  } catch (error) {
    setloading(false)
  }
  
  }

// console.log("djdjdjjcmcmcmcmcmcmcmcmc",selectedcate)
  const onSelect = (selectedList,selectedItem) => {

    if (selectedList.length > 2) {
      setoption([])
      // Remove the last selected item if the limit is exceeded
      // multiselectRef.current.removeSelectedItem(selectedItem);
      // alert("You can select a maximum of 3 options.");
      return;
    } else {
      const allexist = selectedList.some(item => item.id === '00');
      if(allexist){
        setoption([]);
      }else{
        if(selectedList[0].id !== '00'){
          fetchcategory(1)
        }
      }
      // console.log('Selected values:', allexist);
      setFieldValue("categories",selectedList);
    }
  };

  // console.log("total value os sis is",showoptions)

  const onRemove = (selectedList) => {
    setshowoptions(true);
    fetchcategory(1);
    // setselectedcate(selectedList);
    // console.log('Selected values:', selectedList);
    setFieldValue("categories",selectedList);
    if(selectedList.length === 0){
    setFieldValue("categories",'');
    fetchcategory(0)
    }
  };

  useEffect(()=>{
  
    fetchcategory()
      
    },[])

  return (
    <>
      {tokenvalue ? <Header /> : <Loginheader />}

      <div
        className={
          tokenvalue
            ? "breadcrumb-main marginfromtop"
            : "breadcrumb-main marginfromtop23"
        }
      >
        {/* <div className="breadcrumb-main marginfromtop"> */}
        <div className="container m-0">
          <div className="row">
            <div className="col">
              <div className="breadcrumb-contain">
                <div>
                  <ul>
                    <li>
                      <a href="/home">home</a>
                    </li>
                    <li>{`>`}</li>
                    <li>
                      <a href="javascript:void(0)">Voucher Request</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="contact-page section-big-py-space b-g-light">
        <div className="custom-container">
          <div className="row section-big-pb-space">
            <div className="col-xl-8 offset-xl-2">
              <h3 className="text-center mb-3" style={{ fontSize: "23px" }}>
                 VOUCHER REQUEST
              </h3>
              <form className="theme-form" onSubmit={handleSubmit}>
                <div className="row">





                  {/* <div className="col-md-12">
                    <div className="form-group">
                      <label className="acounttitle" htmlFor="email">
                        Voucher Type <span style={{ color: "red" }}>*</span>
                      </label>
                       <select
                                    class="form-control"
                                    value={values.vouchertype}
                                    onChange={(e) => {setFieldValue("vouchertype",e.target.value);changeotherfield(e.target.value,setFieldValue)}}
                                    style={{ fontSize: "12px",padding:'12px 25px' }}
                                    aria-label="Default select example"

                                  >
                                    <option value="" >Select Voucher Type</option>
                                    <option value="Gift Voucher">Gift Voucher</option>
                                    <option value="Shopping Voucher">Shopping Voucher</option>
                                    <option value="Discount Voucher">Discount Voucher</option>
                                  </select>
                      {errors.vouchertype && touched.vouchertype ? (
                        <p className="form-group" style={{ color: "red" }}>
                          {errors.vouchertype}
                        </p>
                      ) : null}
                    </div>
                  </div>

                  <div className={values.vouchertype == "Discount Voucher" ? "col-md-3" : "col-md-4"}>
                    <div className="form-group">
                       <select
                                    class="form-control"
                                    value={values.Clubbable}
                                    onChange={(e) => {setFieldValue("Clubbable",e.target.value)}}
                                    style={{ fontSize: "12px",padding:'12px 25px' }}
                                    aria-label="Default select example"

                                  >
                                    <option value="Clubbable">Clubbable</option>
                                    {values.vouchertype == "Discount Voucher" ? "" : <option value="NonClubbable">NonClubbable</option>}
                                  </select>
                      {errors.Clubbable && touched.Clubbable ? (
                        <p className="form-group" style={{ color: "red" }}>
                          {errors.Clubbable}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className={values.vouchertype == "Discount Voucher" ? "col-md-3" : "col-md-4"}>
                    <div className="form-group">
                       <select
                                    class="form-control"
                                    value={values.GST}
                                    onChange={(e) => {setFieldValue("GST",e.target.value)}}
                                    style={{ fontSize: "12px",padding:'12px 25px' }}
                                    aria-label="Default select example"

                                  >
                                   {values.vouchertype == "Discount Voucher" ? "" : <option value="GST Included">GST Included</option>}
                                    <option value="GST Payable">GST Payable</option>
                                  </select>
                      {errors.GST && touched.GST ? (
                        <p className="form-group" style={{ color: "red" }}>
                          {errors.GST}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className={values.vouchertype == "Discount Voucher" ? "col-md-3" : "col-md-4"} >
                    <div className="form-group">
                       <select
                                    class="form-control"
                                    value={values.Shipping}
                                    onChange={(e) => {setFieldValue("Shipping",e.target.value)}}
                                    style={{ fontSize: "12px",padding:'12px 25px' }}
                                    aria-label="Default select example"

                                  >
                                   {values.vouchertype != "Gift Voucher" && values.vouchertype != "Discount Voucher" ? <option value="Shipping Included">Shipping Included</option> : ''}
                                   <option value="Shipping Payable">Shipping Payable</option>
                                  {values.vouchertype == "Gift Voucher" && values.vouchertype != "Discount Voucher" ? <option value="Shipping Free">Shipping Free</option> : ''} 
                                  </select>
                      {errors.Shipping && touched.Shipping ? (
                        <p className="form-group" style={{ color: "red" }}>
                          {errors.Shipping}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  {values.vouchertype == "Discount Voucher" ? <div className="col-md-3">
                    <div className="form-group">
                       <select
                                    class="form-control"
                                    value={values.valuetype}
                                    onChange={(e) => {setFieldValue("valuetype",e.target.value)}}
                                    style={{ fontSize: "12px",padding:'12px 25px' }}
                                    aria-label="Default select example"

                                  >
                                   <option value="Percentage">Percentage</option>
                                   <option value="Fixed">Fixed Price</option>
                                  </select>
                      {errors.valuetype && touched.valuetype ? (
                        <p className="form-group" style={{ color: "red" }}>
                          {errors.valuetype}
                        </p>
                      ) : null}
                    </div>
                  </div> : ''} */}
                  




               
                 
                 
                  <div className="col-md-12">
                    <div className="form-group">
                      <label className="acounttitle" htmlFor="email">
                      Voucher Denomination <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="tel"
                        className="form-control"
                        min={1000}
                        name="amount"
                        style={{ fontSize: "12px" }}
                        onChange={(e)=>{setFieldValue("amount",e.target.value.replace(/\D/g, ''))}}
                        id="last-name"
                        placeholder={values.valuetype == "Percentage" ? "Voucher value" : "Min. 1000 Rs"}
                        value={values.amount}
                      />
                      {errors.amount && touched.amount ? (
                        <p className="form-group" style={{ color: "red" }}>
                          {errors.amount}
                        </p>
                      ) : null}
                    </div>
                  </div>


                  <div className="col-md-12">
                    <div className="form-group">
                      <label className="acounttitle" htmlFor="name">
                      No. of Vouchers <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control "
                        name="quantity"
                        style={{ fontSize: "12px" }}
                        id="quantity"
                        placeholder="No. of Vouchers"
                        value={values.quantity}
                        onChange={(e)=>{setFieldValue("quantity",e.target.value)}}
                        // onBlur={handleBlur}
                      />
                      {errors.quantity && touched.quantity ? (
                        <p className="form-group" style={{ color: "red" }}>
                          {errors.quantity}
                        </p>
                      ) : null}
                      {/* {errors.name && <p style={{color:'red'}}>{errors.name}</p>} */}
                    </div>
                    {/* {console.log("values and erroir", errors, values)} */}
                  </div>


                  <div className="col-md-12">
                    <div className="form-group">
                      <label className="acounttitle" htmlFor="name">
                      Product Categories/Preference <span style={{ color: "red" }}>*</span>
                      </label>

                      {/* <div 
                        style={{visibility: showoptions == false ? "visible" : "hidden",height: showoptions == false ? "auto" : "1px",fontSize: "12px"}}
                        >
                  <Multiselect options={[]} selectedValues={seletedoption} style={{fontSize: "12px"}} displayValue="category_bread_crump" ref={multiselectRef} onSelect={onSelect}
                        onRemove={onRemove} className="form-control newsize"  /> 
                        </div> */}


                        <div 
                        style={{fontSize: "12px"}}
                        // style={{visibility: showoptions == true ? "visible" : "hidden",height: showoptions == true ? "auto" : "1px",fontSize: "12px"}}
                        >
 <Multiselect options={option} displayValue="category_bread_crump" ref={multiselectRef} onSelect={onSelect}
                        style={{fontSize: "12px"}}
                        onRemove={onRemove} className="form-control newsize"  />     
                        </div>
                       
                      {errors.categories && touched.categories ? (
                        <p className="form-group" style={{ color: "red" }}>
                          {errors.categories}
                        </p>
                      ) : null}

                    </div>
                  </div>

                  <div className="col-md-12 ">
                    <button className="btn btn-normal" type="submit">
                      {" "}
                      Send Request
                    </button>
                  </div>
                  <div
                    className="col-md-12 pt-2"
                    style={{ display: success == "" ? "none" : "block" }}
                  >
                    <div class="alert alert-success" role="alert">
                      {success}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          {/* <div className="row">
                        <div className="col-lg-12 map">
                            <div className="theme-card">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3506.5193026892966!2d77.14320397495169!3d28.494019790358195!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1ef6576ec313%3A0xf46be40c4d72420!2sOneUp%20Brands%20(%20A%20Unit%20of%20One%20Up%20Trade%20Pvt.%20Ltd.)!5e0!3m2!1sen!2sin!4v1712723660069!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </div>
                    </div> */}
        </div>
      </section>

      <Footer/>
    </>
  );
};
export default Voucherrequest;