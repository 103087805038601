import React, { useCallback, useEffect, useState } from "react";
import Header from "../components/Header";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { gettoken, getvoucher } from "../Localstorage/Store";
import MobileSubcategory from "../components/MobileSubcategory";
import ReactPaginate from "react-paginate";
import Loader from "../components/Loader";


const ImageDisplay = ({ images }) => {
  // Step 1: Sort the images by display_order
  const sortedImages = images.sort((a, b) => a.display_order - b.display_order);

  // Step 2: Get the image with the lowest display_order
  const lowestOrderImage = sortedImages[0];

  return (
    <div>
      {lowestOrderImage && (
        <img src={`https://apanel.oneupbrands.com/media/${lowestOrderImage.original}`} alt={lowestOrderImage.caption || 'Image'} />
      )}
    </div>
  );
};
const Categoryforsearch = () => {
  const { name } = useParams();
  // console.log("params",name);
  const location = useLocation();
  const nvg = useNavigate();
  const tokenvalue = gettoken();
  // category filter start here
  const [pricevalue, setpricevalue] = useState(0);
  const [fixminprice, setfixminprice] = useState(0);
  const [fixmaxprice, setfixmaxprice] = useState(0);
  // const [showattr, setshowattr] = useState({});
  const updateDataValue2 = (key, newData) => {
    setshowattr((prevData) => {
      const updatedData = { ...prevData, [key]: newData };
      return updatedData;
    });
  };
  // console.log("min price",fixminprice,"max price here 2",fixmaxprice);
  const [attribute, setattribute] = useState({
    min_price: 0,
    sort_by: "",
  });






  const [showattr, setshowattr] = useState({});
  // console.log("first ppppp",attribute,"lllkkdkdkd",showattr);
  const [brand, setbrand] = useState(true);
  const [categoriesbtn, setcategoriesbtn] = useState(true)
  const [filter, setfilter] = useState(false);
  const [currentwdith, setcurrentwdith] = useState(window.innerWidth);
  const [showsidebar, setshowsidebar] = useState(false);
  const [sortby, setsortby] = useState(false);
  const [loading, setloading] = useState(true);
  const [data, setData] = useState([]);
  const [data4, setData4] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setpageSize] = useState(40);
  // let pageSize = 12;

  // console.log("data  of by category",data)
  // const transfer = (productid,pname) => {
  //   nvg("/productdetails");
  //   window.location.reload();
  // };

  const transfer = (productid,pname) => {
    nvg("/productdetails", {
      state: {
        id: productid,
        // categoryid: id,
        // pagename: name,
        pname: pname,
        // categoryid: location.state?.id,
        // pagename: location.state?.pagename,
      },
    });
    window.location.reload();
  };

  const [categories, setCategories] = useState([]);
  const [totalrecords, settotalrecords] = useState(0);

  const filterdata = async (pagenumber) => {
    console.log("page numer is here",pagenumber);
      setloading(true);
      let urlapi = `${process.env.REACT_APP_API_URL}homepageapi/search/${name}?offset=${pagenumber}`;
      // let urlapi = `${process.env.REACT_APP_API_URL}homepageapi/shop_by_brand/${name}/?offset=${pagenumber}`;
      const response = await axios.get(urlapi);

settotalrecords(response.data.count);
setData(response.data.results.results);
setData4(response.data.results.categories)
setloading(false)
  }


  const localvouhcer = getvoucher();

  const transformData = (originalData) => {
    const transformedData = [];
    const idMap = new Map();

    originalData.forEach((item) => {
        if (!idMap.has(item.id)) {
            idMap.set(item.id, transformedData.length);
            transformedData.push({
                id: item.id,
                name: item.name,
                sub: [item.sub]
            });
        } else {
            const index = idMap.get(item.id);
            transformedData[index].sub.push(item.sub);
        }
    });

    return transformedData;
};
  useEffect(() => {
    // if (location.state.id) {
    if (name) {
      async function fetchcategory2(name) {
        setloading(true);
        


        let urlapi
        if(localvouhcer){
           urlapi = `${process.env.REACT_APP_API_URL}homepageapi/search/${name}?code_list=${localvouhcer}`;
        }else{
           urlapi = `${process.env.REACT_APP_API_URL}homepageapi/search/${name}`;
        }
        const response = await axios.get(urlapi);

  settotalrecords(response.data.count);
  setData(response.data.results.results);
  let newcate_format = transformData(response.data.results.categories);

  // setData4(response.data.results.categories)
  setData4(newcate_format)
  const updatedAttributes2 = {};
  newcate_format.forEach((item, index) => {
      updatedAttributes2[item.name] = true;
  });
  setshowattr((prevData) => ({
    ...prevData,
    ...updatedAttributes2,
  }));
  setloading(false)


  
      }

      fetchcategory2(name)
     
    }

    async function fetchcategory() {
      let url = `${process.env.REACT_APP_API_URL}api/categories/`;
      const response = await axios.get(url);
      setCategories(response.data);
    }

    // const existbred = name.includes('>');
    // if(existbred){
    //   const splitbred = name.split('>')
    //   setcategroyone(splitbred[0]);
    //   setcategorytwo(splitbred[1]);
    // }else{
    //   setcategroyone(name);
    // }
    fetchcategory();
    
  }, []);

  const pageCount = Math.ceil(totalrecords / 40);
  // const pageCount = Math.ceil(totalrecords / pageSize);
  const handlePageClick = (selectedPage) => {
    // console.log("eeeeeeeeeeeeeefffffffffff",selectedPage)
    // if(selectedPage == "first"){
    //   setCurrentPage(0);
    // }else{
    //   setCurrentPage(selectedPage.selected);
    // }
  };
  return (
    <>
      <Header />

      <div className="category-header7" style={{ zIndex: 9991 }}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="category-contain">
                <div className="category-left showandhide ">
                  <div className="header-category">
                    <a className="category-toggle">
                      <i className="fa fa-bars" />
                      pages
                    </a>
                    <ul
                      id="main-menu"
                      className={
                        showsidebar == true
                          ? "collapse-category show sm pixelstrap sm-horizontal open"
                          : "collapse-category show sm pixelstrap sm-horizontal"
                      }
                    >
                      <li
                        className="back-btn"
                        onClick={() => setshowsidebar(false)}
                      >
                        <i className="fa fa-angle-left" /> back
                      </li>

                      {categories.map((item, index) => (
                        <MobileSubcategory value={item} />
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="searchbar-input ajax-search the-basics">
          <div className="input-group">
            <span className="input-group-text">
              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                width="28.931px"
                height="28.932px"
                viewBox="0 0 28.931 28.932"
                style={{ enableBackground: "new 0 0 28.931 28.932" }}
                xmlSpace="preserve"
              >
                <g>
                  <path d="M28.344,25.518l-6.114-6.115c1.486-2.067,2.303-4.537,2.303-7.137c0-3.275-1.275-6.355-3.594-8.672C18.625,1.278,15.543,0,12.266,0C8.99,0,5.909,1.275,3.593,3.594C1.277,5.909,0.001,8.99,0.001,12.266c0,3.276,1.275,6.356,3.592,8.674c2.316,2.316,5.396,3.594,8.673,3.594c2.599,0,5.067-0.813,7.136-2.303l6.114,6.115c0.392,0.391,0.902,0.586,1.414,0.586c0.513,0,1.024-0.195,1.414-0.586C29.125,27.564,29.125,26.299,28.344,25.518z M6.422,18.111c-1.562-1.562-2.421-3.639-2.421-5.846S4.86,7.983,6.422,6.421c1.561-1.562,3.636-2.422,5.844-2.422s4.284,0.86,5.845,2.422c1.562,1.562,2.422,3.638,2.422,5.845s-0.859,4.283-2.422,5.846c-1.562,1.562-3.636,2.42-5.845,2.42S7.981,19.672,6.422,18.111z"></path>
                </g>
              </svg>
            </span>
            <input
              type="search"
              className="form-control typeahead"
              placeholder="Search a Product"
            />
            <span className="input-group-text close-searchbar">
              <svg
                viewBox="0 0 329.26933 329"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0"></path>
              </svg>
            </span>
          </div>
        </div>
      </div>

      {/* breadcrumb start */}
      <div className="breadcrumb-main marginfromtop breadcrumbpadding">
        <div className="container m-0">
          <div className="row">
            <div className="col">
              <div className="breadcrumb-contain">
                <div>
                  <ul>
                    <li>
                      <a href="/home">home</a>
                    </li>
                    {/* <li style={{fontSize:"12px"}}>&gt;</li>
                <li><a href="javascript:void(0)">Category</a></li> */}
                    <li style={{ fontSize: "12px" }}>&gt;</li>
                    <li>
                      <a href="javascript:void(0)">
                        Search Result
                        {/* {location.state?.pagename} */}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* breadcrumb End */}
      {/* section start */}
      <section
        className="section-big-pt-space ratio_asos b-g-light"
        style={{ padding: "0px" }}
      >
        <div className="collection-wrapper" style={{ background: "#f9f9f9" }}>
          <div className="custom-container">
            <div className="row categoryfixclass1" style={{ background: "#f9f9f9" }}>
              <div
                className="col-sm-3 collection-filter category-page-side categoryfixclass2"
                style={{
                  zIndex: currentwdith < 990 ? 9991 : 1,
                  left: "-15px",
                  display:
                    currentwdith < 990
                      ? filter == true
                        ? "block"
                        : "none"
                      : "block",
                  padding: "10px",
                }}
              >
                {/* side-bar colleps block stat */}
                <div className="collection-filter-block creative-card creative-inner category-side">
                  {/* brand filter start */}
                  <div
                    className="collection-mobile-back"
                    onClick={() => {
                      setfilter(!filter);
                      console.log("click me");
                    }}
                  >
                    <span className="filter-back">
                      <i className="fa fa-angle-left" aria-hidden="true" /> back 
                    </span>
                  </div>
                  {/* <div className="collection-collapse-block open">
                          <h4>
                          CATEGORIES
                            </h4>
                            <h6 style={{color:categorytwo == null ? `black` : `#878787`,padding: `10px 0px 10px 8px`}}>{` ${categroyone}`}</h6>
                            <h6 style={{color:`#black`,position:'relative',left:'10px',display:categorytwo == null ? 'none' : 'block',padding: `0 0 10px 8px`}}>{`${categorytwo}`}</h6>
                            </div> */}

                        {/* <div className="collection-collapse-block open">
                          <h3
                            className={"collapse-block-title dynamic-after2"}
                            // className={
                            //   item.name.length > 6
                            //     ? "collapse-block-title dynamic-after2"
                            //     : "collapse-block-title dynamic-after"
                            // }
                            onClick={() => {
                              categoriesbtn == false
                                ? setcategoriesbtn(true)
                                : setcategoriesbtn(false);
                            }}
                         
                          >
                           CATEGORIES
                            {
                              // item.name.length > 7 ?
                              <style>
                                {`.dynamic-after::after {
            left:51px;
          }  .dynamic-after2::after {
            left:110px;
          }`}
                              </style>
                              //  : <style>
                              //   {`
                              //   .dynamic-after::after {
                              //     left:50px;
                              //   }
                              // `}
                              // </style>
                            }
                          </h3>
                          <div
                            className="collection-collapse-block-content"
                            style={{
                              display:
                              categoriesbtn == true ? "block" : "none",
                            }}
                          >
                            <div className="collection-brand-filter">
                            {data4.map((item2, index2)=>(

                                <div className="custom-control custom-checkbox  form-check collection-filter-checkbox">
{console.log("dddddd",item2)}                                  
                          
                                    <input
                                      type="checkbox"
                                      className="custom-control-input form-check-input"
                                      id="item2"
                                      onClick={()=>{nvg(`/category/${item2[0]}/${item2[1]}/none`)}}
                                    />
                                 
                                  <label
                                    className="custom-control-label form-check-label"
                                    htmlFor="item2"
                                  >
                                    {item2[1]}
                                  </label>
                                </div>
                            ))}
                           
                            </div>
                          </div>
                        </div> */}
                    
                    {data4.map((item, index) => (
                        <div className="collection-collapse-block open" style={{display:item.name ? "block" : "none"}}>
                          <h3
                            className={
                              item.name.length > 6
                                ? item.name.length > 9 ? item.name.length > 13 ? item.name.length > 18 ? "collapse-block-title dynamic-after5" : "collapse-block-title dynamic-after4" : "collapse-block-title dynamic-after3" : "collapse-block-title dynamic-after2"
                                : "collapse-block-title dynamic-after"
                            }
                            // onClick={() => {
                            //   setbrand(!brand);
                            // }}
                            onClick={() => {
                              showattr[item.name] == false
                                ? updateDataValue2(item.name, true)
                                : updateDataValue2(item.name, false);
                            }}
                          >
                            {/* brand */}
                            {item.name}
                            {
                              // item.name.length > 7 ?
                              <style>
                                {`.dynamic-after::after {
            left:60px;
          }  .dynamic-after2::after {
            left:91px;
          } .dynamic-after3::after {
            left:115px;
          }.dynamic-after4::after {
            left:128px;
          }.dynamic-after5::after {
            left:182px;
          }`}
                              </style>
                              //  : <style>
                              //   {`
                              //   .dynamic-after::after {
                              //     left:50px;
                              //   }
                              // `}
                              // </style>
                            }
                          </h3>
                          <div
                            className="collection-collapse-block-content"
                            style={{
                              display:
                                showattr[item.name] == true ? "block" : "none",
                            }}
                          >
                            <div className="collection-brand-filter">
                            {item.sub[0] ? item.sub.map((item2, index2) => (
                                <div className="custom-control custom-checkbox  form-check collection-filter-checkbox">
                                  {/* {attribute[item.name] !==
                                    item2.value_option__option ? (
                                      <input
                                        type="checkbox"
                                        className="custom-control-input form-check-input"
                                        onClick={() => {
                                          setpageSize(0);
                                          updateDataValue('offset',0);
                                          updateDataValue(
                                            item.name,
                                            item2.value_option__option
                                          );
                                        }}
                                        id="item2"
                                      />
                                    ) : (
                                      <input
                                        type="checkbox"
                                        onClick={() => {
                                          // setbrandvlu(false);
                                          // setbrandtxt("");
                                          updateDataValue('offset',0);
                                          updateDataValue(item.name, "");
                                          // filterdata();
                                        }}
                                        className="custom-control-input form-check-input"
                                        id="item2"
                                        checked
                                      />
                                    )
                                  } */}
                                  
                                  
                                  <input
                                      type="checkbox"
                                      className="custom-control-input form-check-input"
                                      id="item23"
                                      onClick={()=>{nvg(`/category/${item2?.id}/${item?.name} > ${item2?.name}/none`)}}
                                    />
                                  
                                  <label
                                    className="custom-control-label form-check-label"
                                    htmlFor="item23fff"
                                    onClick={()=>{nvg(`/category/${item2?.id}/${item?.name} > ${item2?.name}/none`)}}
                                  >
                                    {item2?.name}
                                  </label>
                                </div>
                              )) : <div className="custom-control custom-checkbox  form-check collection-filter-checkbox">
                              {/* {attribute[item.name] !==
                                item2.value_option__option ? (
                                  <input
                                    type="checkbox"
                                    className="custom-control-input form-check-input"
                                    onClick={() => {
                                      setpageSize(0);
                                      updateDataValue('offset',0);
                                      updateDataValue(
                                        item.name,
                                        item2.value_option__option
                                      );
                                    }}
                                    id="item2"
                                  />
                                ) : (
                                  <input
                                    type="checkbox"
                                    onClick={() => {
                                      // setbrandvlu(false);
                                      // setbrandtxt("");
                                      updateDataValue('offset',0);
                                      updateDataValue(item.name, "");
                                      // filterdata();
                                    }}
                                    className="custom-control-input form-check-input"
                                    id="item2"
                                    checked
                                  />
                                )
                              } */}
                              
                              
                              <input
                                  type="checkbox"
                                  className="custom-control-input form-check-input"
                                  id="item23"
                                  onClick={()=>{nvg(`/category/${item?.id}/${item?.name}/none`)}}
                                />
                              
                              <label
                                className="custom-control-label form-check-label"
                                htmlFor="item23fff"
                                onClick={()=>{nvg(`/category/${item?.id}/${item?.name}/none`)}}
                              >
                                {item?.name}
                              </label>
                            </div>}
                            </div>
                          </div>
                        </div>
                      ))}
                </div>
              </div>

              {/* filter for sort by start here */}

              <div
                className="col-sm-3 collection-filter category-page-side"
                style={{
                  zIndex: currentwdith < 790 ? 9991 : 1,
                  left: "-15px",
                  display:
                    currentwdith < 700
                      ? sortby == true
                        ? "block"
                        : "none"
                      : "none",
                }}
              >
                {/* side-bar colleps block stat */}
                <div className="collection-filter-block creative-card creative-inner category-side">
                  {/* brand filter start */}
                  <div
                    className="collection-mobile-back"
                    onClick={() => {
                      setsortby(!sortby);
                    }}
                  >
                    <span className="filter-back">
                      <i className="fa fa-angle-left" aria-hidden="true" /> back
                    </span>
                  </div>
                  <div className="collection-collapse-block open">
                    <h3
                      className="collapse-block-title newarrow mt-0"
                      onClick={() => {
                        setbrand(!brand);
                      }}
                    >
                      Sort By
                    </h3>
                    <div
                      className="collection-collapse-block-content"
                      style={{ display: brand == true ? "block" : "none" }}
                    >
                      <div className="collection-brand-filter">
                        {/* <div className="custom-control custom-checkbox  form-check collection-filter-checkbox"> */}
                          {/* {url.includes(`sort_by=l2h`) ? (
                            <input
                              type="checkbox"
                              className="custom-control-input form-check-input"
                              onClick={() => {
                                // updateDataValue("offset", 0);
                                // updateDataValue("sort_by", "l2h");
                                updateDataValueforsort("sort_by", "l2h")
                              }}
                              id="item2"
                              checked
                            />
                          ) : url.includes(`sort_by=`) ? (
                            <input
                              type="checkbox"
                              className="custom-control-input form-check-input"
                              onClick={() => {
                                // updateDataValue("offset", 0);
                                // updateDataValue("sort_by", "l2h");
                                updateDataValueforsort("sort_by", "l2h")
                              }}
                              id="item2"
                              checked
                            />
                          ) : (
                            <input
                              type="checkbox"
                              onClick={() => {
                                // updateDataValue("offset", 0);
                                // updateDataValue("sort_by", "");
                                updateDataValueforsort("sort_by", "l2h")
                              }}
                              className="custom-control-input form-check-input"
                              id="item2"
                              
                            />
                          )} */}
                          {/* <input
                            type="checkbox"
                            className="custom-control-input form-check-input"
                            id="zara"
                          /> */}
                          {/* <label
                            className="custom-control-label form-check-label"
                            htmlFor="zara"
                          >
                            Price-Low to High
                          </label> */}
                        {/* </div> */}
                        {/* <div className="custom-control custom-checkbox  form-check collection-filter-checkbox">
                       
                          {url.includes(`sort_by=h2l`) ? (
                            <input
                              type="checkbox"
                              className="custom-control-input form-check-input"
                              onClick={() => {
            
                                updateDataValueforsort("sort_by", "h2l")
                              }}
                              id="item2"
                              checked
                            />
                          ) : (
                            <input
                              type="checkbox"
                              onClick={() => {
                            
                                updateDataValueforsort("sort_by", "h2l")
                              }}
                              className="custom-control-input form-check-input"
                              id="item2"
                              
                            />
                          )}
                          <label
                            className="custom-control-label form-check-label"
                            htmlFor="vera-moda"
                          >
                            Price-High to Low
                          </label>
                        </div> */}
                        {/* <div className="custom-control custom-checkbox  form-check collection-filter-checkbox">

                          {url.includes(`sort_by=new_added`) ? (
                            <input
                              type="checkbox"
                              className="custom-control-input form-check-input"
                              onClick={() => {
                                updateDataValueforsort("sort_by", "new_added")
                              }}
                              id="item2"
                              checked
                            />
                          ) : (
                            <input
                              type="checkbox"
                              onClick={() => {
                                updateDataValueforsort("sort_by", "new_added")
                              }}
                              className="custom-control-input form-check-input"
                              id="item2"
                              
                            />
                          )}
                          <label
                            className="custom-control-label form-check-label"
                            htmlFor="forever-21"
                          >
                            Newest First
                          </label>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* filter for sort by end here */}
              <div className="collection-content col">
                <div className="page-main-content p-md-0">
                  <div className="row">
                    <div className="col-sm-12">
                      <div
                        className="collection-product-wrapper"
                        style={{ background: "#f9f9f9" }}
                      >
                        <div className="product-top-filter">
                          <div
                            className="row"
                            style={{ background: "#f9f9f9" }}
                          ></div>
                          <div className="row">
                            <div className="col-12">
                              <div className="product-filter-content">
                                <div
                                  className="search-count text-start"
                                  style={{
                                    border: "none",
                                    padding: "20px 0px 7px 0px",
                                  }}
                                >
                                  {/* <h5 style={{fontWeight: 400, fontSize: 13}}>Showing Products 1-24 of 10 Result</h5> */}
                                  <h5
                                    style={{
                                      fontSize: 13,
                                      color: "black",
                                      fontWeight: 100,
                                    }}
                                  >
                                    {/* Showing Products{" "}
                                    {(currentPage == 0 ? currentPage : currentPage - 1) * pageSize }-
                                    {Math.min(
                                      (currentPage == 0 ? currentPage + 1 : currentPage ) * 12,
                                      totalrecords
                                    )}{" "}
                                    of {totalrecords} Result */}


                                    Showing Products{" "}
    {Math.min((currentPage - 1) * pageSize + 1, totalrecords)}-
    {Math.min(currentPage * pageSize, totalrecords)}{" "}
    of {totalrecords} Results
                                  </h5>
                                </div>
                                <div
                                  className="collection-view"
                                  style={{
                                    width: "100%",
                                    paddingBottom: "0px",
                                  }}
                                >
                                  {/* <ul>
                                    <li
                                      style={{
                                        fontSize: 13,
                                        fontWeight: 600,
                                        paddingBottom: "4px",
                                      }}
                                    >
                                      Sort By
                                    </li>
                                    <li
                                      style={{
                                        fontSize: 13,
                                        marginRight: 12,
                                        paddingBottom: "4px",
                                        color:
                                          url.includes(`sort_by=l2h`) ? "#230bb3"
                                            : url.includes(`sort_by`) ? "" : "#230bb3",
                                        fontWeight:
                                          url.includes(`sort_by=l2h`) ? "550"
                                            : url.includes(`sort_by`) ? "400" : "550",
                                        borderBottom:
                                        url.includes(`sort_by=l2h`) ? "2px solid #230bb3"
                                            : url.includes(`sort_by`) ? "" : "2px solid #230bb3",
                                      }}
                                      onClick={() => {
                                        // updateDataValue("offset", 0);
                                        updateDataValueforsort("sort_by", "l2h");
                                      }}
                                    >
                                      Price - Low to High
                                    </li>
                                    <li
                                      style={{
                                        fontSize: 13,
                                        marginRight: 12,
                                        paddingBottom: "4px",
                                        color:
                                        url.includes(`sort_by=h2l`) ? "#230bb3"
                                            : "",
                                        fontWeight:
                                        url.includes(`sort_by=h2l`) ? "550"
                                            : "400",
                                        borderBottom:
                                        url.includes(`sort_by=h2l`) ? "2px solid #230bb3"
                                            : "",
                                      }}
                                      onClick={() => {
                                        // updateDataValue("offset", 0);
                                        updateDataValueforsort("sort_by", "h2l");
                                      }}
                                    >
                                      Price - High to Low
                                    </li>
                                    <li
                                      style={{
                                        fontSize: 13,
                                        marginRight: 12,
                                        paddingBottom: "4px",
                                        color:
                                        url.includes(`sort_by=new_added`) ? "#230bb3"
                                            : "",
                                        fontWeight:
                                        url.includes(`sort_by=new_added`) ? "550"
                                            : "400",
                                        borderBottom:
                                        url.includes(`sort_by=new_added`) ? "2px solid #230bb3"
                                            : "",
                                      }}
                                      onClick={() => {
                                        // updateDataValue("offset", 0);
                                        updateDataValueforsort("sort_by", "new_added");
                                      }}
                                    >
                                      Newest First
                                    </li>
                                  </ul> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="product-wrapper-grid product">
                          <div className="row removepadding additionalgap">
                            {" "}
                            {loading == true ? (
                              <h2
                                style={{
                                  textAlign: "center",
                                  paddingTop: "15px",
                                  fontWeight: "600",
                                  fontSize: "20px",
                                }}
                              >
                                <Loader />
                              </h2>
                            ) : data[0] ? (
                              data.map((item, index) => (
                                <div className="col-xl-3 col-md-4 col-sm-6 col-12">
                                  <div
                                    className="bg-white catbox"
                                    style={{ margin: "3px 4px" }}
                                  >
                                    {/* <div className="product-imgbox" > */}
                                    <div className="product-imgbox " style={{ position: "relative", overflow: "hidden" }}>

                                    {item.stock_record.num_in_stock <= 0 && (<div className="overlay" onClick={() => {
                                              transfer(item.id, item.title);
                                            }}></div>)}
                                        {item.stock_record.num_in_stock <= 0 && (
                                          <div className="out-of-stock">Out of Stock</div>
                                        )}

{/* {item.stock_record.num_in_stock != 0 && item?.voucher_status == true ? (
                                          <div className="vouchervalid">Voucher Applicable</div>
                                        ) : ''} */}
                                        {item.stock_record.num_in_stock != 0 && item?.voucher_status == true ? (
                                          <div className="vouchervalid px-0" >
                                            <div className="specifywidth">
                                            <img
                        // src={`http://localhost:3000/images/greenbox.png`}
                        src={`${process.env.REACT_APP_API_PUBLIC_URL}images/greenbox.png`}
                        // className="img-fluid"
                        style={{width:'74%'}}
                        // style={{height:'100%',position:'absolute',left:'0px'}}
                        alt="logo"
                      />
                                 <p style={{position:"absolute",top: '6px',left: '4px',fontSize: '11px',fontWeight: '500'}}>   Voucher Applicable </p>
                                            </div>
                                            </div>
                                        ) : ''} 
                                      <div className="product-front">
                                        <button
                                          type="button"
                                          className="btn fixedhight"
                                          style={{ width: "100%" }}
                                          onClick={() => {
                                            transfer(item.id,item.title);
                                          }}
                                        >
                                          {" "}
                                          {/* <img
                                            src={`${process.env.REACT_APP_API_URL}media/${item?.image[item.image.length - 1]?.original}`}
                                            className="img-fluid  "
                                            alt="product"
                                          /> */}
                                           <ImageDisplay images={item?.image} />{" "}
                                        </button>
                                      </div>
                                    </div>
                                    <div className="product-detail detail-center detail-inverse">
                                      <div className="detail-title">
                                        <div className="detail-left">
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "center",
                                            }}
                                          >
                                            {" "}
                                            <button
                                              type="button"
                                              className="btn"
                                              onClick={() => {
                                                transfer(item.id,item.title);
                                              }}
                                            >
                                              <h6
                                                className="price-title catbox2"
                                                style={{
                                                  fontSize: "14px",
                                                  fontWeight: "600",
                                                  color:"#230bb3",
                                                  height:'20px',
                                                  display: "-webkit-box",
                                                  WebkitLineClamp: "1",
                                                  WebkitBoxOrient: "vertical",
                                                  overflow: "hidden",
                                                
                                                }}
                                              >
                                                 {/* {item.title.length > 10 ? item.title.substring(0,18) + '...' : item.title} */}
                                                {item.att_val.filter(item => item.attribute__name.toLowerCase() == "brand").map((item,index)=>(
                                                  item.value_option__option
                                                ))}
                                              </h6>{" "}
                                              <h6
                                                className="price-title catbox2"
                                                style={{
                                                  fontSize: "14px",
                                                  fontWeight: "600",
                                                  display: "-webkit-box",
                                                  WebkitLineClamp: "1",
                                                  WebkitBoxOrient: "vertical",
                                                  overflow: "hidden",
                                                }}
                                              >
                                                {item?.title}
                                              </h6>{" "}
                                            </button>
                                          </div>
                                        </div>
                                        <div
                                          className="detail-right"
                                          style={{ width: "100%" }}
                                        >
                                          <div
                                            className="price"
                                            style={{ width: "100%" }}
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                color: "#000",
                                                fontSize: "14px",
                                                fontWeight:'500'
                                              }}
                                            >
                                              {" "}
                                              ₹{parseInt(item?.stockrecords__price)}{" "} {item.stock_record?.discount == 0 ? '' : <span style={{fontSize: '13px',color: '#c1c1c1',lineHeight: '20px',textDecoration: 'line-through',paddingLeft:'3px',fontWeight:'400'}}>₹{item.stock_record?.mrp}</span>}{item.stock_record?.discount == 0 ? '' : <span style={{fontSize: '12px',color: '#388e3c',lineHeight: '20px',paddingLeft:'3px',fontWeight:'400'}}>{item.stock_record?.discount_type == "amount" ? `(₹${item.stock_record?.discount} off)` : `(${item.stock_record?.discount} %off)`}</span>}
                                            </div>
                                            {/* <div className="price text-align-center" style={{display:'flex',justifyContent:'center'}}>  ₹{item.price} </div> */}
                                          </div>
                                        </div>
                                      </div>
                                      
                                    </div>
                                  </div>
                                </div>
                              ))
                            ) : (
                              <h2
                                style={{
                                  textAlign: "center",
                                  paddingTop: "17px",
                                  fontWeight: "600",
                                  fontSize: "20px",
                                }}
                              >
                                No Result Found !
                              </h2>
                            )}
                          </div>
                        </div>
                        {/* {loading == true ? "" : data[0] ? ( */}
                      {
                        data[0] ? 
                       <div className="product-pagination">
                          <div className="theme-paggination-block">
                            <div className="row mobilemargin">
                              <div className="col-xl-12 col-md-12 col-sm-12">
                                <ReactPaginate
                                  pageCount={pageCount}
                                  pageRangeDisplayed={5}
                                  marginPagesDisplayed={2}
                                  onPageChange={(e) => {
                                    setCurrentPage(e.selected + 1);
                                    filterdata(e.selected * 40);
                                  }}
                                  containerClassName="pagination"
                                  breakClassName="page-item"
                                  activeClassName="active"
                                  pageClassName="page-item"
                                  previousLabel={
                                    <li className="page-item">
                                      <a
                                        className="page-link"
                                        href="javascript:void(0)"
                                        aria-label="Previous"
                                      >
                                        <span aria-hidden="true">
                                          <i
                                            className="fa fa-chevron-left"
                                            aria-hidden="true"
                                          />
                                        </span>{" "}
                                        <span className="sr-only">
                                          Previous
                                        </span>
                                      </a>
                                    </li>
                                  }
                                  nextLabel={
                                    <li className="page-item">
                                      <a
                                        className="page-link"
                                        href="javascript:void(0)"
                                        aria-label="Next"
                                      >
                                        <span aria-hidden="true">
                                          <i
                                            className="fa fa-chevron-right"
                                            aria-hidden="true"
                                          />
                                        </span>{" "}
                                        <span className="sr-only">Next</span>
                                      </a>
                                    </li>
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div> : ''}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="header7 bottomdgn">
                <div className="custom-container">
                  <div className="row">
                    <div className="col-12">
                      <div
                        className="header-contain"
                        style={{ padding: "8px 0px 0px 0px" }}
                      >
                        <div
                          className="collection-product-wrapper"
                          style={{ width: "100%" }}
                        >
                          <div className="product-top-filter">
                            <div className="row">
                              <div
                                className="col-xl-12"
                                style={{
                                  paddingTop: "10px",
                                  display: "flex",
                                  justifyContent: "space-around",
                                }}
                              >
                                <div
                                  className=""
                                  onClick={() => {
                                    setfilter(!filter);
                                  }}
                                >
                                  <span
                                    className="filter "
                                    style={{ paddingright: "100px" }}
                                  >
                                    <i
                                      className="fa fa-filter"
                                      aria-hidden="true"
                                      style={{
                                        fontSize: "24px",
                                        color: "#4150b5",
                                      }}
                                    />
                                  </span>
                                </div>

                                <div
                                  className="toggle-nav"
                                  style={{ justifyContent: "center" }}
                                  onClick={() => setshowsidebar(!showsidebar)}
                                >
                                  <i className="fa fa-bars sidebar-bar" />
                                </div>

                                {/* <div className="collection-collapse">
                                  <h3
                                    className="collapse-block-title mt-0"
                                    onClick={() => {
                                      setsortby(!sortby);
                                    }}
                                  >
                                    <i
                                      class="fa-solid fa-arrow-down-wide-short"
                                      style={{ color: "#4150b5" }}
                                      onClick={() => {
                                        setsortby(!sortby);
                                        console.log("click me");
                                      }}
                                    />
                                  </h3>
                                </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* section End */}

      {/* <Footer /> */}
    </>
  );
};

export default Categoryforsearch;
